import { ClearRounded, FlashlightOffOutlined } from "@mui/icons-material";
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogTitle,
	Divider,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { DataGrid } from "@mui/x-data-grid";
import AddFiles from "components/EboStoreV4/CommonComponents/AddFiles/AddFiles";
import CustomTextField from "components/EboStoreV4/CommonComponents/CustomTextfield/CustomTextfield";
import RenderInputField from "components/EboStoreV4/CommonComponents/RenderInputFiled/RenderInputFiled";
import {
	addOrEditModelAction,
	autoUpdatestoreMainFieldsData,
	billerDetailsTableColumns,
	employeeDetailsTableColumns,
	fileTypeColumns,
	getColDefaultValue,
	leaseDetails,
	salesEmployeeRoleColumns,
	salesProjectionDetailsColumns,
	storeBasicDetailsFields,
	storeMainFields,
	storesLinkedTables,
	storeTypeDetails,
} from "components/EboStoreV4/data";
import { monthStringArr } from "data/eboPaymentsData";
import { arrType, constraintsOptions, converAttrToDatagridCol } from "data/globalData";
import { inputTypes, statusOptions } from "data/pimData";
import { agreementData } from "data/supplierPageData";
import { useEffect, useState } from "react";

export const fileTypesData = [
	// { name: "Industry Type Certification", Key: "INDUSTRY_CERT" },
	{ name: "Pancard", Key: "PANCARD" },
	{ name: "GST Certificate", Key: "GST_CERT" },
	{ name: "Blank Cheque Photo Copy", Key: "BLANK_CHEQUE_PHOTO" },
];

const AddOrEditModal = ({ modal, setModal, type, employeeDetails, setEmployeeDetails, handleCreateEmployee }) => {
	const defFields = (() => {
		let obj = {};
		salesProjectionDetailsColumns?.forEach((attr) => {
			obj[attr.key] = getColDefaultValue(attr);
		});
		return obj;
	})();

	const collectFloorNumber = (array, column) => {
		let data = array?.map((row) => {
			return {
				...row,
				name: row?.[column],
				key: row?.[column],
			};
		});
		return data;
	};
	const [saleProj, setSaleProj] = useState(defFields);
	const [addedSalesProj, setAddedSalesProj] = useState([]);

	const handleModalclose = () => {
		setModal(false);
	};

	const handleCallback = () => {
		setEmployeeDetails({
			...employeeDetails,
			eboStoreSalesProjectionDetails: [...employeeDetails?.eboStoreSalesProjectionDetails, ...addedSalesProj],
		});
		setModal(false);
	};
	const handleFormSubmit = async (e) => {
		e.preventDefault();
		handleCreateEmployee(storesLinkedTables.sales_projection_details.seqMethod, addedSalesProj, handleCallback);
	};
	const addEmployee = () => {
		let noDataCount = 0;
		Object.keys(saleProj)?.forEach((attr) => {
			if (saleProj[attr] === defFields[attr]) {
				noDataCount += 1;
			}
		});
		if (noDataCount > 0) {
			return;
		}
		const data = {
			id: new Date().getTime(),
			...saleProj,
			ebo_store_v4_id: employeeDetails?.store_code,
		};
		setAddedSalesProj(() => [...addedSalesProj, data]);
		setSaleProj(defFields);
	};

	console.log("addedSalesProj", addedSalesProj);
	return (
		<Dialog maxWidth="lg" fullWidth open={modal} onClose={handleModalclose} component="form" onSubmit={handleFormSubmit}>
			<Box p={2}>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Typography variant="h6">
						{type === addOrEditModelAction.UPDATE ? "Update" : "Add"} Sales Projection Details
					</Typography>
					<Box>
						<IconButton onClick={handleModalclose}>
							<ClearRounded />
						</IconButton>
					</Box>
				</Box>
				{addedSalesProj.length > 0 && (
					<Box>
						<DataGrid
							rows={addedSalesProj}
							getRowId={(row) => row.id}
							columns={[...salesProjectionDetailsColumns?.map((col) => converAttrToDatagridCol(col))]}
							sx={{
								minHeight: 220,
								maxHeight: 300,
								width: "100%",
								border: "none",
								"& .MuiDataGrid-row": {
									// borderBottom: "1px solid lightgray",
									cursor: "pointer",
									border: "none",
								},
								"& .MuiDataGrid-columnHeader": {
									cursor: "pointer",
									background: grey[300],
								},
							}}
							columnHeaderHeight={35}
							rowHeight={40}
							disableAutosize
							// onRowClick={({ row }) => handleModalOpen(addOrEditModelAction.UPDATE, row)}
							disableRowSelectionOnClick
							pageSizeOptions={[5, 10]}
						/>
					</Box>
				)}
				<Stack spacing={1} width="100%">
					{" "}
					<Box display="flex" columnGap={"10px"} rowGap="10px" flexWrap="wrap" width="100%">
						{salesProjectionDetailsColumns?.map((row) => {
							let options = {};
							if (row.key === "brand") {
								options = {
									type: arrType,
									list: collectFloorNumber(employeeDetails?.eboStoreTypeDetails, "brand"),
								};
							}
							if (row.key === "month") {
								options = {
									type: arrType,
									list: monthStringArr,
								};
							}
							return (
								<RenderInputField
									column={{ ...row, options }}
									data={saleProj}
									setData={setSaleProj}
									width="24%"
									selectOptObjName={"options"}
								/>
							);
						})}
					</Box>
				</Stack>
				<Button
					variant="contained"
					sx={{ justifySelf: "center" }}
					// disabled={employeeDetails.status === statusOptions.PUBLISHED.key}
					onClick={addEmployee}>
					Add Sales Projection
				</Button>

				<DialogActions sx={{ pt: 2 }}>
					<Button variant="outlined" sx={{ justifySelf: "center" }} onClick={handleModalclose}>
						Cancel
					</Button>
					<Button
						variant="contained"
						sx={{ justifySelf: "center" }}
						disabled={employeeDetails.status === statusOptions.PUBLISHED.key || addedSalesProj.length === 0}
						type="submit">
						{type === addOrEditModelAction.UPDATE ? "UPDATE" : "CREATE"}
					</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
};

export default AddOrEditModal;
