import { Box, Button, Divider, InputLabel, Stack, Typography } from "@mui/material";
import { converAttrToDatagridCol, datatypes } from "data/globalData";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GlobalState } from "context/GlobalContext";
import { addOrEditModelAction, salesProjectionDetailsColumns, storesLinkedTables } from "components/EboStoreV4/data";
import { grey } from "@mui/material/colors";
import AddOrEditModal from "./AddOrEditModal.js/AddOrEditModal";
import { statusOptions } from "data/pimData";

const SalesProjectionDetails = ({ employeeDetails, setEmployeeDetails, handleCreateEmployee }) => {
	const [addOrEditModal, setAddOrEditModal] = useState(false);
	const [addOrEditModalType, setAddOrEditModalType] = useState(addOrEditModelAction.CREATE);
	const { setSnackBar, snackBar, setSnackBarOpen, loginUser, setLoadingOpen } = GlobalState();
	const navigate = useNavigate();

	return (
		<Stack p={1} width="95%" margin="auto" spacing={1}>
			<Box display="flex" justifyContent="space-between" pt={1} pb={2}>
				<Typography variant="h5">{storesLinkedTables.sales_projection_details.name}</Typography>
				<Button
					size="small"
					disabled={employeeDetails.status === statusOptions.PUBLISHED.key}
					variant="contained"
					onClick={() => setAddOrEditModal(true)}>
					Add
				</Button>
			</Box>
			<Box>
				<DataGrid
					rows={employeeDetails?.eboStoreSalesProjectionDetails || []}
					getRowId={(row) => row.id}
					columns={[...salesProjectionDetailsColumns?.map((col) => converAttrToDatagridCol(col))]}
					sx={{
						minHeight: 220,
						maxHeight: 300,
						width: "100%",
						border: "none",
						"& .MuiDataGrid-row": {
							// borderBottom: "1px solid lightgray",
							cursor: "pointer",
							border: "none",
						},
						"& .MuiDataGrid-columnHeader": {
							cursor: "pointer",
							background: grey[300],
						},
					}}
					columnHeaderHeight={35}
					rowHeight={40}
					disableAutosize
					// onRowClick={({ row }) => handleModalOpen(addOrEditModelAction.UPDATE, row)}
					disableRowSelectionOnClick
					pageSizeOptions={[5, 10]}
				/>
			</Box>
			{addOrEditModal && (
				<AddOrEditModal
					modal={addOrEditModal}
					setModal={setAddOrEditModal}
					type={addOrEditModalType}
					employeeDetails={employeeDetails}
					setEmployeeDetails={setEmployeeDetails}
					handleCreateEmployee={handleCreateEmployee}
				/>
			)}
		</Stack>
	);
};

export default SalesProjectionDetails;
