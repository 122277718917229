import { ClearRounded, FlashlightOffOutlined } from "@mui/icons-material";
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogTitle,
	Divider,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { DataGrid } from "@mui/x-data-grid";
import AddFiles from "components/EboStoreV4/CommonComponents/AddFiles/AddFiles";
import CustomTextField from "components/EboStoreV4/CommonComponents/CustomTextfield/CustomTextfield";
import RenderInputField from "components/EboStoreV4/CommonComponents/RenderInputFiled/RenderInputFiled";
import {
	addOrEditModelAction,
	autoUpdatestoreMainFieldsData,
	billerDetailsTableColumns,
	fileTypeColumns,
	getColDefaultValue,
	leaseDetails,
	storeBasicDetailsFields,
	storeMainFields,
	storesLinkedTables,
	storeTypeDetails,
} from "components/EboStoreV4/data";
import { constraintsOptions, converAttrToDatagridCol } from "data/globalData";
import { inputTypes, statusOptions } from "data/pimData";
import { agreementData } from "data/supplierPageData";
import { useEffect, useState } from "react";

export const fileTypesData = [
	// { name: "Industry Type Certification", Key: "INDUSTRY_CERT" },
	{ name: "Pancard", Key: "PANCARD" },
	{ name: "GST Certificate", Key: "GST_CERT" },
	{ name: "Blank Cheque Photo Copy", Key: "BLANK_CHEQUE_PHOTO" },
];

const AddOrEditModal = ({
	modal,
	setModal,
	type,
	defaultData,
	createDetailsApi,
	selectedStore,
	setSelectedStore,
	handleUpdateDetails,
	handleFileUploadDetails,
	handleGetStoreFiles,
}) => {
	const defFields = (() => {
		let obj = {};
		billerDetailsTableColumns?.forEach((attr) => {
			obj[attr.key] = getColDefaultValue(attr);
		});
		return obj;
	})();
	const [billerDetails, setBillerDetails] = useState(type === addOrEditModelAction.UPDATE ? defaultData : defFields);

	const handleModalclose = () => {
		setModal(false);
	};
	const [allFiles, setAllFiles] = useState([]);

	const handleCallback = (data) => {
		setAllFiles(data);
	};

	useEffect(() => {
		defaultData !== undefined && handleGetStoreFiles(storesLinkedTables.store_biller_details.key, defaultData?.id, handleCallback);
	}, []);

	const handleBillerCallbackFun = (data) => {
		if (type === addOrEditModelAction.UPDATE) {
			let filtredData = selectedStore?.eboStoreBillerDetails.filter((data) => data.id !== billerDetails.id);
			let billerDetail = [...filtredData, billerDetails];
			setSelectedStore({ ...selectedStore, eboStoreBillerDetails: billerDetail });
		} else {
			let billerDetail = [...selectedStore?.eboStoreBillerDetails, data];
			setSelectedStore({ ...selectedStore, eboStoreBillerDetails: billerDetail });
		}
		setBillerDetails(defFields);
		setModal(false);
	};
	const handleFormSubmit = async (e) => {
		e.preventDefault();
		const filtredFiles = allFiles.filter((data) => data?.isNew);
		//this if for file upload
		let updatedData = await handleFileUploadDetails(
			filtredFiles,
			selectedStore.store_code,
			storesLinkedTables.store_biller_details.key
		);
		let updatedBiller = { ...billerDetails, ebo_store_v4_id: selectedStore.store_code, files: updatedData };
		if (type === addOrEditModelAction.UPDATE) {
			await handleUpdateDetails(storesLinkedTables.store_biller_details.seqMethod, updatedBiller, handleBillerCallbackFun);
		} else {
			createDetailsApi(storesLinkedTables.store_biller_details.seqMethod, updatedBiller, handleBillerCallbackFun);
		}
	};

	const handleAddFile = (data) => {
		setAllFiles([...allFiles, data]);
	};
	const formStack = {
		width: { xs: "80%", sm: "40%", md: "25%" },
	};
	return (
		<Dialog maxWidth="lg" fullWidth open={modal} onClose={handleModalclose} component="form" onSubmit={handleFormSubmit}>
			<Box p={2}>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Typography variant="h6">{type === addOrEditModelAction.UPDATE ? "Update" : "Add"} Biller Details</Typography>
					<Box>
						<IconButton onClick={handleModalclose}>
							<ClearRounded />
						</IconButton>
					</Box>
				</Box>
				<Stack spacing={1} width="100%">
					{" "}
					<Box display="flex" columnGap={"10px"} rowGap="10px" flexWrap="wrap" width="100%">
						{billerDetailsTableColumns?.map((row) => {
							return (
								<RenderInputField
									column={row}
									data={billerDetails}
									setData={setBillerDetails}
									width="24%"
									selectOptObjName={"option_data"}
									dependencyColData={autoUpdatestoreMainFieldsData}
								/>
							);
						})}
					</Box>
					<AddFiles
						columns={fileTypeColumns}
						tableName={storesLinkedTables.store_biller_details.key}
						rowId={defaultData?.id}
						handleAddFile={handleAddFile}
						files={allFiles}
						selectedStore={selectedStore}
					/>
				</Stack>

				<DialogActions sx={{ pt: 2 }}>
					<Button variant="outlined" sx={{ justifySelf: "center" }} onClick={handleModalclose}>
						Cancel
					</Button>
					<Button
						variant="contained"
						sx={{ justifySelf: "center" }}
						disabled={selectedStore.status === statusOptions.PUBLISHED.key}
						type="submit">
						{type === addOrEditModelAction.UPDATE ? "UPDATE" : "CREATE"}
					</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
};

export default AddOrEditModal;
