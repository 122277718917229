import React, { createContext, useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import RareBoxLogo from "../Logos/RareBoxLogo";
import { GlobalState } from "./GlobalContext";
import axios from "axios";
import { userTypes } from "data/authData";
import { CustomLinearProgress } from "components/ReusableComponents/CustomLinearProgress/CustomLinearProgress";
import { selectedStoreDummyData } from "components/EboStoreV4/data";

const EboStoreContext = createContext();
// api url

const EboStoreProvider = ({ children }) => {
	const [selectedStore, setSelectedStore] = useState(selectedStoreDummyData);

	const navigate = useNavigate();

	return (
		<EboStoreContext.Provider value={{ selectedStore, setSelectedStore }}>
			<Outlet />
		</EboStoreContext.Provider>
	);
};

export const EboStoreState = () => {
	return useContext(EboStoreContext);
};

export default EboStoreProvider;
