import { Box, Button, Divider, InputLabel, Stack, Typography } from "@mui/material";
import { EboStoreState } from "context/EboStoreContext";
import {
	addOrEditModelAction,
	eboAllStoresPage,
	eboAllStorespageUrl,
	getColumnValue,
	leaseDetails,
	storeBasicDetailsFields,
	storeCapexTableColumns,
	storeLeaseAgreeAssocObj,
	storeTypeDetails,
} from "../../../data";
import { inputTypes, statusOptions } from "data/pimData";
import { datatypes } from "data/globalData";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import StoreTypeDetails from "../BasicDetails/StoreTypeDetails/StoreTypeDetails";
import axios from "axios";
import { GlobalState } from "context/GlobalContext";
import EmployeeDetailsTable from "./EmployeeDetailsTable/EmployeeDetailsTable";
import SalesProjectionDetails from "./SalesProjectionDetails/SalesProjectionDetails";

const EmployeeDetails = () => {
	const { selectedStore, setSelectedStore } = EboStoreState();
	const [employeeDetails, setEmployeeDetails] = useState();
	const [selectedLease, setSelectedLease] = useState(selectedStore);
	const [basicDetailModal, setBasicDetailModal] = useState(false);
	const [areaModal, setAreaDetailModal] = useState(false);
	const [capexModal, setCapexModal] = useState(false);
	const [filesDetails, setFilesDetails] = useState([]);
	let [searchParams, setSearchParams] = useSearchParams();
	const { setSnackBar, snackBar, setSnackBarOpen, loginUser, setLoadingOpen } = GlobalState();
	const navigate = useNavigate();
	const goToStoresPage = () => {
		navigate(eboAllStorespageUrl);
	};

	useEffect(() => {
		const fetch = async (store_code) => {
			try {
				setLoadingOpen(true);
				const res = await axios.get(`/api/ebo_store_v4/store_employee/${store_code}`);
				console.log("setSelectedStore", res.data);
				setEmployeeDetails(res.data.store);
				setLoadingOpen(false);
			} catch (error) {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "Unable to get store details",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				setLoadingOpen(false);
			}
		};
		let store_code = searchParams.get("store");
		if (store_code) fetch(store_code);
		else {
			setSelectedStore(null);
		}
	}, [searchParams]);

	const handleCreateEmployee = async (type, body, callBackFun) => {
		setLoadingOpen(true);
		await axios
			.post(`/api/ebo_store_v4/create_employee/${type}`, body)
			.then((res) => {
				if (res.status === 200) {
					console.log("res.data", res.data?.result);
					callBackFun(res.data?.result);
					setLoadingOpen(false);
				}
			})
			.catch((err) => {
				console.log("error", err);
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: `unable to create ${type} stores details`,
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				setLoadingOpen(false);
			});
	};
	console.log("3456dfghtrewsddfg", employeeDetails);

	if (employeeDetails)
		return (
			<Stack pl={1} pr={1} width="95%" margin="auto" spacing={1}>
				<Box display="flex" justifyContent="space-between">
					<Typography variant="h5">
						{/* <Button startIcon={<ArrowBackIosIcon fontSize="20" />} size="large" onClick={() => navigate(-1)} /> */}
						Employee Details - {employeeDetails?.store_code}
					</Typography>
				</Box>
				<EmployeeDetailsTable
					employeeDetails={employeeDetails}
					setEmployeeDetails={setEmployeeDetails}
					handleCreateEmployee={handleCreateEmployee}
				/>
				<SalesProjectionDetails
					employeeDetails={employeeDetails}
					setEmployeeDetails={setEmployeeDetails}
					handleCreateEmployee={handleCreateEmployee}
				/>
			</Stack>
		);
};

export default EmployeeDetails;
