import { DeleteRounded } from "@mui/icons-material";
import { Box, Button, Divider, IconButton, InputLabel, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { DataGrid } from "@mui/x-data-grid";
import {
	addOrEditModelAction,
	billerDetailsTableColumns,
	getColumnValue,
	leaseDetails,
	storeTypeDetails,
} from "components/EboStoreV4/data";
import { useState } from "react";
import AddOrEditModal from "./AddOrEditModal/AddOrEditModal";
import { datatypes } from "data/globalData";
import HistoryModal from "./HistoryModal/HistoryModal";
import moment from "moment";
import { inputTypes } from "data/globalData";
import { statusOptions } from "data/pimData";

const StoreTypeDetails = ({ typeData, createDetailsApi, selectedStore, setSelectedStore }) => {
	const [addOrEditModal, setAddOrEditModal] = useState(false);
	const [historyModal, setHistoryModal] = useState(false);
	const [addOrEditModalType, setAddOrEditModalType] = useState(addOrEditModelAction.CREATE);

	const handleModalOpen = (type) => {
		setAddOrEditModal(true);
		setAddOrEditModalType(type);
	};
	const handleHistoryModalOpen = () => {
		setHistoryModal(true);
	};
	console.log(";djk", typeData);
	return (
		<Box display="flex" flexWrap="wrap" columnGap={"10px"} rowGap="10px" width="100%">
			<Box display="flex" flexWrap="wrap" width="100%" justifyContent="space-between">
				<Typography variant="button">(G1) Store Type Details</Typography>
				<Box display="flex" columnGap={1}>
					<Button size="small" variant="outlined" onClick={handleHistoryModalOpen}>
						Logs
					</Button>
					<Button
						size="small"
						disabled={selectedStore.status === statusOptions.PUBLISHED.key}
						variant="contained"
						onClick={() => handleModalOpen(addOrEditModelAction.UPDATE)}>
						Edit
					</Button>
				</Box>
				<Divider sx={{ width: "100%" }} />
			</Box>
			{storeTypeDetails?.map((row) => {
				return (
					<Stack className="vendor-input" spacing={0} width={"23%"}>
						<InputLabel>
							{row?.name}
							{/* {field?.required ? <span style={{ color: "red" }}>*</span> : ""} */}
						</InputLabel>
						<>
							<Typography variant="h6" fontSize={16}>
								{getColumnValue(row, typeData)}
							</Typography>
						</>
					</Stack>
				);
			})}
			{addOrEditModal && (
				<AddOrEditModal
					modal={addOrEditModal}
					setModal={setAddOrEditModal}
					type={addOrEditModelAction.UPDATE}
					defaultData={typeData}
					createDetailsApi={createDetailsApi}
					selectedStore={selectedStore}
					setSelectedStore={setSelectedStore}
				/>
			)}
			{historyModal && <HistoryModal modal={historyModal} setModal={setHistoryModal} selectedStore={selectedStore} />}
		</Box>
	);
};

export default StoreTypeDetails;
