import { ClearRounded } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogTitle, Divider, IconButton, Stack, Typography } from "@mui/material";
import axios from "axios";
import RenderInputField from "components/EboStoreV4/CommonComponents/RenderInputFiled/RenderInputFiled";
import {
	autoUpdateStoreBasicDetailsData,
	autoUpdatestoreMainFieldsData,
	getColDefaultValue,
	storeBasicDetailsFields,
	storeMainFields,
	storeTypeDetails,
} from "components/EboStoreV4/data";
import { useState } from "react";
import { GlobalState } from "context/GlobalContext";

const CreateNewModal = ({ modal, setModal, fetchStores }) => {
	const { setSnackBar, snackBar, setSnackBarOpen, loginUser, setLoadingOpen } = GlobalState();

	const handleModalclose = () => {
		setModal(false);
	};
	const handleFormSubmit = async (e) => {
		setLoadingOpen(true);
		e.preventDefault();
		console.log("Store Details", newStoreBasicDetailsFields, newStoreTypeDetails);
		let body = {};
		body["newStoreBasicDetailsFields"] = newStoreBasicDetailsFields;
		body["newStoreTypeDetails"] = newStoreTypeDetails;
		await axios
			.post("/api/ebo_store_v4/create-new-store", body)
			.then((res) => {
				if (res.status === 201) {
					setSnackBarOpen(true);
					setSnackBar({
						...snackBar,
						message: `Store created successfully`,
						severity: "success",
					});
					setLoadingOpen(false);
					fetchStores();
					setModal(false);
				}
			})
			.catch((err) => {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: `Error creating Store`,
					severity: "error",
				});
				setLoadingOpen(false);
			});
	};
	// const [newStoreMainFields, setNewStoreMainFields] = useState(() => {
	// 	let obj = {};
	// 	storeMainFields?.forEach((attr) => {
	// 		obj[attr.key] = getColDefaultValue(attr);
	// 	});
	// 	return obj;
	// });
	const [newStoreBasicDetailsFields, setNewStoreBasicDetailsFields] = useState(() => {
		let obj = {};
		storeBasicDetailsFields?.forEach((attr) => {
			obj[attr.key] = getColDefaultValue(attr);
		});
		return obj;
	});
	const [newStoreTypeDetails, setNewStoreTypeDetails] = useState(() => {
		let obj = {};
		storeTypeDetails?.forEach((attr) => {
			obj[attr.key] = getColDefaultValue(attr);
		});
		return obj;
	});
	return (
		<Dialog maxWidth="md" fullWidth open={modal} onClose={handleModalclose} component="form" onSubmit={handleFormSubmit}>
			<Box p={2}>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Typography variant="h6">Create Store</Typography>
					<Box>
						<IconButton onClick={handleModalclose}>
							<ClearRounded />
						</IconButton>
					</Box>
				</Box>
				<Stack spacing={1} width="100%">
					{" "}
					{/* <Box display="flex" columnGap={"10px"} rowGap="10px" flexWrap="wrap" width="100%">
						{storeMainFields?.map((row) => {
							return (
								<RenderInputField
									column={row}
									data={newStoreMainFields}
									setData={setNewStoreMainFields}
									width="24%"
									selectOptObjName={"option_data"}
									dependencyColData={autoUpdatestoreMainFieldsData}
								/>
							);
						})}
					</Box>
					<Divider orientation="horizontal" /> */}
					<Box display="flex" flexWrap="wrap" columnGap={"10px"} rowGap="10px" width="100%">
						<Typography variant="button" width="100%">
							Store Type Details
						</Typography>
						{storeTypeDetails?.map((row) => {
							return (
								<RenderInputField
									column={row}
									data={newStoreTypeDetails}
									setData={setNewStoreTypeDetails}
									width="24%"
									selectOptObjName={"option_data"}
									dependencyColData={autoUpdatestoreMainFieldsData}
								/>
							);
						})}
					</Box>
					<Divider orientation="horizontal" />
					<Box display="flex" flexWrap="wrap" columnGap={"10px"} rowGap="10px" width="100%">
						<Typography variant="button" width="100%">
							Basic Details
						</Typography>
						{storeBasicDetailsFields?.map((row) => {
							return (
								<RenderInputField
									column={row}
									data={newStoreBasicDetailsFields}
									setData={setNewStoreBasicDetailsFields}
									width="24%"
									selectOptObjName={"option_data"}
									dependencyColData={autoUpdateStoreBasicDetailsData}
								/>
							);
						})}
					</Box>
				</Stack>

				<DialogActions sx={{ pt: 2 }}>
					<Button variant="outlined" sx={{ justifySelf: "center" }} onClick={handleModalclose}>
						Cancel
					</Button>
					<Button variant="contained" sx={{ justifySelf: "center" }} type="submit">
						Create
					</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
};

export default CreateNewModal;
