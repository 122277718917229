import { useState } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import Typography from "@mui/material/Typography";
import Reports from "./Reports/Reports";
import StoreTidMaster from "./StoreTidMaster/StoreTidMaster";
import FileUpload from "./FileUpload/FileUpload";
import PaymentTypeQueries from "./PaymentTypeQueries/PaymentTypeQueries";
import Summary from "./Summary/Summary.js";

//for tab section
function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const tabStyle = { textTransform: "capitalize", fontSize: "16px", minWidth: "170px" };

const POSReco = () => {
	const [tab, setTab] = useState(0);

	const handleTabChange = (event, newValue) => {
		setTab(newValue);
	};

	return (
		<Stack width="90vw" maxWidth="1900px" margin="auto" spacing={2}>
			<Typography variant="h4" width="100%" pt={4} pb={2}>
				POS COLLECTION RECONCILIATION
			</Typography>
			<Box height="450px">
				<Tabs
					value={tab}
					onChange={handleTabChange}
					aria-label="basic tabs example"
					sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
					<Tab sx={tabStyle} label="STORE TID MASTER" {...a11yProps(0)} />
					<Tab sx={tabStyle} label="FILE UPLOAD" {...a11yProps(1)} />
					{/* <Tab sx={tabStyle} label="PAYMENT TYPES" {...a11yProps(2)} /> */}
					<Tab sx={tabStyle} label="SUMMARY" {...a11yProps(2)} />
					<Tab sx={tabStyle} label="REPORTS" {...a11yProps(3)} />
				</Tabs>
				<Box display={tab === 0 ? "block" : "none "}>
					<StoreTidMaster />
				</Box>
				<Box display={tab === 2 ? "block" : "none"}>
					<Summary />
				</Box>
				<Box display={tab === 1 ? "block" : "none "}>
					<FileUpload />
				</Box>
				{/* <Box display={tab === 2 ? "block" : "none "}>
					<PaymentTypeQueries />
				</Box> */}

				<Box display={tab === 3 ? "block" : "none"}>
					<Reports />
				</Box>
			</Box>
		</Stack>
	);
};

export default POSReco;
