import { ClearRounded, DeleteRounded } from "@mui/icons-material";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	Divider,
	IconButton,
	InputLabel,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { storeTypeDetails } from "components/EboStoreV4/data";
import { GlobalState } from "context/GlobalContext";
import { useEffect, useState } from "react";

const HistoryModal = ({ modal, setModal, selectedStore }) => {
	const [data, setDate] = useState([]);
	const { setSnackBar, snackBar, setSnackBarOpen, loginUser, setLoadingOpen } = GlobalState();
	const handleModalclose = () => {
		setModal(false);
	};
	const handleRowDelete = (row) => {
		console.log(row);
	};

	const handleStoreTypeLogs = async () => {
		setLoadingOpen(true);
		await axios
			.get(`/api/ebo_store_v4/get_store_type_logs/${selectedStore.store_code}`)
			.then((res) => {
				if (res.status === 200) {
					console.log(res.data);
					setDate(res.data);
					setLoadingOpen(false);
				}
			})
			.catch((err) => {
				console.log("error", err);
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "unable to fetch stores logs",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				setLoadingOpen(false);
			});
	};
	useEffect(() => {
		handleStoreTypeLogs();
	}, []);

	return (
		<Dialog maxWidth="md" fullWidth open={modal} onClose={handleModalclose}>
			<Box p={2}>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Typography variant="h6">Change Logs</Typography>
					<Box>
						<IconButton onClick={handleModalclose}>
							<ClearRounded />
						</IconButton>
					</Box>
				</Box>
				<Box pt={1} width="100%">
					<DataGrid
						rows={data ? data : []}
						columns={[
							...storeTypeDetails?.map((col) => {
								return {
									field: col?.key,
									headerName: col?.name?.toUpperCase(),
									width: 150,
									headerAlign: "center",
									align: "center",
								};
							}),
							// {
							// 	headerName: "DELETE",
							// 	width: 150,
							// 	headerAlign: "center",
							// 	align: "center",

							// 	renderCell: (params) => {
							// 		return (
							// 			<Box display="flex" justifyContent="center" alignItems="center">
							// 				<IconButton variant="contained" color="error" onClick={() => handleRowDelete(params.row)}>
							// 					<DeleteRounded />
							// 				</IconButton>
							// 			</Box>
							// 		);
							// 	},
							// },
						]}
						sx={{
							minHeight: 220,
							maxHeight: 300,
							width: "100%",
							border: "none",
							"& .MuiDataGrid-row": {
								// borderBottom: "1px solid lightgray",
								cursor: "pointer",
								border: "none",
							},
							"& .MuiDataGrid-columnHeader": {
								cursor: "pointer",
								background: grey[300],
							},
							"& .MuiDataGrid-columnHeaders": {
								background: grey[300],
								// mb: 2,
								border: "none",
								borderRadius: 1,
								// padding: "10px 0px",
							},
							"& .MuiDataGrid-footer": {
								background: grey[300],
								// mb: 2,
								border: "none",
								borderRadius: 1,
								// padding: "10px 0px",
							},
						}}
						columnHeaderHeight={35}
						rowHeight={40}
						disableAutosize
						disableRowSelectionOnClick
						pageSizeOptions={[5, 10]}
					/>
				</Box>

				{/* <DialogActions sx={{ pt: 2 }}>
					<Button variant="outlined" sx={{ justifySelf: "center" }} onClick={handleModalclose}>
						Cancel
					</Button>
					<Button variant="contained" sx={{ justifySelf: "center" }} type="submit">
						{type === addOrEditModelAction.UPDATE ? "Update" : "Add"}
					</Button>
				</DialogActions> */}
			</Box>
		</Dialog>
	);
};

export default HistoryModal;
