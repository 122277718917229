import axios from "axios";
import { rootProtectedPath, services } from "data/authData";
import { arrType, datatypes, inputTypes } from "data/globalData";
import { constraints } from "data/pimData";
import moment from "moment";

export const addOrEditModelAction = { UPDATE: "UPDATE", CREATE: "CREATE" };

export const eboSelectedStorePage = "selected-store";
export const eboSelectedStorepageUrl = `/${rootProtectedPath}/${services.ebo_stores.path}/${eboSelectedStorePage}`;
export const eboAllStoresPage = "stores";
export const eboAllStorespageUrl = `/${rootProtectedPath}/${services.ebo_stores.path}/${eboAllStoresPage}`;

export const storeLeaseAgreeAssocObj = "storeLeaseAgreement";

export const getColumnValue = (row, obj) => {
	if (row?.data_type === datatypes.DATE.key) {
		return moment(obj?.[row.key]).format("DD-MM-YYYY") || "--";
	} else if (row.input_type == inputTypes.MULTIPLE_SELECT.key) {
		return obj?.[row.key]?.join(",") || "--";
	} else {
		return obj?.[row.key] || "--";
	}
};

export const getColDefaultValue = (col) => {
	if (col?.input_type === inputTypes.ENTER.key) {
		return col?.data_type === datatypes.STRING.key
			? ""
			: col?.data_type === datatypes.NUMBER.key
			? 0
			: col?.data_type === datatypes.DECIMAL.key
			? 0.0
			: "";
	} else if (col?.input_type === inputTypes.SELECT.key) {
		return inputTypes.SELECT.key;
	} else if (col?.input_type === inputTypes.MULTIPLE_SELECT.key) return [];

	return "";
};

export const isColumnDefault = (col, value) => {
	if (Array.isArray(value)) return value?.length ? false : true;
	if (value === getColDefaultValue(col) || !value) return true;
	else return false;
};
export const brandCodeList = [
	{
		name: "Select",
		key: "SELECT",
	},
	{
		name: "RR",
		key: "RR",
	},
	{
		name: "RSM",
		key: "RSM",
	},
	{
		name: "THOR",
		key: "THOR",
	},
	{
		name: "RO",
		key: "RO",
	},
	{
		name: "RZ",
		key: "RZ",
	},
];

export const BRAND_OPTIONS = [
	{
		name: "RARE RABBIT",
		key: "RARE RABBIT",
		brand_code: "RR",
	},
	{
		name: "RAREISM",
		key: "RAREISM",
		brand_code: "RSM",
	},
	{
		name: "THOR",
		key: "THOR",
		brand_code: "THOR",
	},
	{
		name: "RAREONES",
		key: "RAREONES",
		brand_code: "RO",
	},
	{
		name: "RAREZ",
		key: "RAREZ",
		brand_code: "RZ",
	},
];

export const STORE_TYPE_DOOR_OPTIONS = [
	{
		name: "RARE RABBIT",
		key: "RARE RABBIT",
		brand_code: "RR",
	},
	{
		name: "RAREISM",
		key: "RAREISM",
		brand_code: "RSM",
	},

	{
		name: "RAREONES",
		key: "RAREONES",
		brand_code: "RO",
	},
	{
		name: "RAREZ",
		key: "RAREZ",
		brand_code: "RZ",
	},
];

export const TIER_OPTIONS = [
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "TIER1",
		value: "TIER1",
	},
	{
		label: "TIER2",
		value: "TIER2",
	},
	{
		label: "TIER3",
		value: "TIER3",
	},
];

export const STORE_TYPE_OPTIONS = [
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "COCO",
		value: "COCO",
	},
	{
		label: "COFO",
		value: "COFO",
	},
	{
		label: "FOFO",
		value: "FOFO",
	},
	{
		label: "FOCO",
		value: "FOCO",
	},
];

export const MALL_HS_OPTIONS = [
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "MALL",
		value: "MALL",
	},
	{
		label: "HIGH STREET",
		value: "HIGH STREET",
	},
	{
		label: "HOTEL",
		value: "HOTEL",
	},
	{
		label: "AIRPORT",
		value: "AIRPORT",
	},
];

export const AGREEMENT_REGISTERED_OPTIONS = [
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "Yes",
		value: "YES",
	},
	{
		label: "No",
		value: "NO",
	},
];

export const GST_REGISTERED_OPTIONS = [
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "N/A",
		value: "N/A",
	},
	{
		label: "REGISTERED",
		value: "REGISTERED",
	},
	{
		label: "TO BE REGISTERED",
		value: "TO BE REGISTERED",
	},
];

export const IS_ACTIVE_OPTIONS = [
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "ACTIVE",
		value: "ACTIVE",
	},
	{
		label: "CLOSED",
		value: "CLOSED",
	},
	{
		label: "INACTIVE",
		value: "INACTIVE",
	},
];

export const ZONE_OPTIONS = [
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "NORTH",
		value: "NORTH",
	},
	{
		label: "SOUTH",
		value: "SOUTH",
	},
	{
		label: "EAST",
		value: "EAST",
	},
	{
		label: "WEST",
		value: "WEST",
	},
	{
		label: "CENTRAL",
		value: "CENTRAL",
	},
];

export const RENT_SCHEDULE_OPTIONS = [
	{
		label: "SELECT",
		value: "SELECT",
	},
	{
		label: "ADVANCE",
		value: "ADVANCE",
	},
	{
		label: "MONTH END",
		value: "MONTH END",
	},
];
export const STOCK_ROOM_OPTIONS = [
	{
		name: "SELECT",
		key: "SELECT",
	},
	{
		name: "YES",
		key: "YES",
	},
	{
		name: "NO",
		key: "NO",
	},
];
export const storeLiscenceTypes = [
	{
		label: "MUSIC LISCENCE",
		value: "MUSIC_LISCENCE",
	},
	{
		label: "TRADE LISCENCE",
		value: "TRADE_LISCENCE",
	},
	{
		label: "SHOP & ESTD.",
		value: "SHOP_AND_ESTABLISHMENT",
	},
];
export const storeDepositTypes = [
	{
		label: "Security Deposit",
		value: "SECURITY_DEPOSIT",
	},
	{
		label: "Cam Deposit",
		value: "CAM_DEPOSIT",
	},
	{
		label: "Electricity Deposit",
		value: "ELECTRICITY_DEPOSIT",
	},
	{
		label: "Deposit Against Goods",
		value: "DEPOSIT_AGAINST_GOODS",
	},
];
export const storeDepositMilestones = [
	{ label: "LOI", value: "LOI" },
	{ label: "Agreement", value: "AGREEMENT" },
	{ label: "Possession", value: "POSSESSION" },
	{ label: "On or before store opening", value: "ON_OR_BEFORE_STORE_OPENING" },
];
export const daysStringCount = [
	"10D",
	"15D",
	"20D",
	"25D",
	"30D",
	"35D",
	"40D",
	"45D",
	"50D",
	"55D",
	"60D",
	"65D",
	"70D",
	"75D",
	"80D",
	"85D",
	"90D",
	"95D",
	"100D",
	"105D",
	"110D",
	"115D",
	"120D",
];

export const monthStringCount = [
	"1M",
	"2M",
	"3M",
	"4M",
	"5M",
	"6M",
	"7M",
	"8M",
	"9M",
	"10M",
	"11M",
	"12M",
	"13M",
	"14M",
	"15M",
	"16M",
	"17M",
	"18M",
	"19M",
	"20M",
	"21M",
	"22M",
	"23M",
	"24M",
	"25M",
	"26M",
	"27M",
	"28M",
	"29M",
	"30M",
	"31M",
	"32M",
	"33M",
	"34M",
	"35M",
	"36M",
	"37M",
	"38M",
	"39M",
	"40M",
	"41M",
	"42M",
	"43M",
	"44M",
	"45M",
	"46M",
	"47M",
	"48M",
	"49M",
	"50M",
	"51M",
	"52M",
	"53M",
	"54M",
	"55M",
	"56M",
	"57M",
	"58M",
	"59M",
	"60M",
	"61M",
	"62M",
	"63M",
	"64M",
	"65M",
	"66M",
	"67M",
	"68M",
	"69M",
	"70M",
	"71M",
	"72M",
	"73M",
	"74M",
	"75M",
	"76M",
	"77M",
	"78M",
	"79M",
	"80M",
	"81M",
	"82M",
	"83M",
	"84M",
	"85M",
	"86M",
	"87M",
	"88M",
	"89M",
	"90M",
	"91M",
	"92M",
	"93M",
	"94M",
	"95M",
	"96M",
	"97M",
	"98M",
	"99M",
];

export const storeMainFields = [
	{
		name: "Brand Code",
		key: "rr_code",
		input_type: inputTypes.AUTO_GENERATE.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 0,
		group: "",
		option_data: {
			type: arrType,
			list: BRAND_OPTIONS,
			getOptionLabel: (row) => {
				return { name: row.name, key: row.brand_code };
			},
		},
	},
	{
		name: "Store No",
		key: "rr_number",
		input_type: inputTypes.AUTO_GENERATE.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.NUMBER.key,
		sort_no: 1,
		group: "",
		placeholder: "Autogenerated",
	},
	{
		name: "Store Code",
		key: "store_code",
		input_type: inputTypes.AUTO_GENERATE.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.NUMBER.key,
		sort_no: 1,
		group: "",
		placeholder: "Autogenerated",
	},
];
export const autoUpdatestoreMainFieldsData = {
	rr_code: {
		col: "brand",
		val: (triggerCol, triggerColVal, colToUpdate, options) => {
			console.log("ranranranranran");
			let arr = BRAND_OPTIONS?.filter((row) => row.brand_code === triggerColVal);
			if (arr?.length > 0) {
				return arr[0].key;
			} else {
				return "";
			}
		},
	},
};
export const autoUpdatestoreLeaseFieldsData = {
	store_opening_date: {
		col: "financial_year",
		val: (triggerCol, triggerColVal, colToUpdate, options, data) => {
			console.log("ranranranranran", data);
			return moment(triggerColVal).format("YYYY");
		},
	},
	fitout_start_date: {
		col: "fitout_period",
		val: (triggerCol, triggerColVal, colToUpdate, options, data) => {
			console.log("ranranranranran", data);
			if (data?.fitout_end_date) {
				return moment(data?.fitout_end_date, "YYYY-MM-DD").diff(moment(triggerColVal, "YYYY-MM-DD"), "days");
			} else {
				return data.financial_year;
			}
		},
	},
	fitout_end_date: {
		col: "fitout_period",
		val: (triggerCol, triggerColVal, colToUpdate, options, data) => {
			console.log("ranranranranran", data);
			if (data?.fitout_start_date) {
				return moment(triggerColVal, "YYYY-MM-DD").diff(moment(data?.fitout_start_date, "YYYY-MM-DD"), "days");
			} else {
				return data.financial_year;
			}
		},
	},
};

function debounce(func, timeout = 300) {
	let timer;
	let result;
	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			result = func.apply(this, args);
		}, timeout);
		console.log(result);
		return result;
	};
}

export const storeTypeDetails = [
	{
		name: "Effective Date",
		key: "effective_date",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.DATE.key,
		sort_no: 0,
		group: "",
	},
	{
		name: "Brand",
		key: "brand",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		option_data: {
			type: arrType,
			list: BRAND_OPTIONS,
		},
	},
	{
		name: "Store Full Name",
		key: "store_full_name",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Store Short Name",
		key: "store_short_name",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 2,
		group: "",
	},
	{
		name: "Store Type",
		key: "store_type",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 3,
		group: "",
		option_data: {
			type: arrType,
			list: STORE_TYPE_OPTIONS,
			getOptionLabel: (row) => {
				return { name: row.label, key: row.value };
			},
		},
	},
	{
		name: "Trading Brands",
		key: "store_type_doors",
		input_type: inputTypes.MULTIPLE_SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.LONGTEXT.key,
		sort_no: 4,
		group: "",
		option_data: {
			type: arrType,
			list: STORE_TYPE_DOOR_OPTIONS,
			// getOptionLabel: (row) => {
			// 	return { name: row.label, key: row.value };
			// },
		},
	},
	{
		name: "Status",
		key: "status",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 5,
		group: "",
		option_data: {
			type: arrType,
			list: IS_ACTIVE_OPTIONS,
			getOptionLabel: (row) => {
				return { name: row.label, key: row.value };
			},
		},
	},
];

export const storeBasicDetailsFields = [
	{
		name: "Unit Number",
		key: "unit_no",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 0,
		group: "",
	},
	{
		name: "No Of Floors",
		key: "floor",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.NUMBER.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Address",
		key: "address",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Pincode",
		key: "pincode",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.NUMBER.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "State",
		key: "state",
		input_type: inputTypes.AUTO_GENERATE.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "City",
		key: "city",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},

	{
		name: "Tier",
		key: "tier",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		option_data: {
			type: arrType,
			list: TIER_OPTIONS,
			getOptionLabel: (row) => {
				return { name: row.label, key: row.value };
			},
		},
	},
	{
		name: "Mall HS",
		key: "mall_hs",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		option_data: {
			type: arrType,
			list: MALL_HS_OPTIONS,
			getOptionLabel: (row) => {
				return { name: row.label, key: row.value };
			},
		},
	},
	{
		name: "Mall Name",
		key: "mall_name",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		option_data: {
			type: arrType,
			list: MALL_HS_OPTIONS,
			getOptionLabel: (row) => {
				return { name: row.label, key: row.value };
			},
		},
	},
	{
		name: "Zone",
		key: "zone",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		option_data: {
			type: arrType,
			list: ZONE_OPTIONS,
			getOptionLabel: (row) => {
				return { name: row.label, key: row.value };
			},
		},
	},
	// {
	// 	name: "Carpet Area",
	// 	key: "carpet_area",
	// 	input_type: inputTypes.ENTER.key,
	// 	constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
	// 	data: {},
	// 	data_type: datatypes.DECIMAL.key,
	// 	sort_no: 1,
	// 	group: "",
	// 	// option_data: {
	// 	// 	type: arrType,
	// 	// 	list: ZONE_OPTIONS,
	// 	// 	getOptionLabel: (row) => {
	// 	// 		return { name: row.label, key: row.value };
	// 	// 	},
	// 	// },
	// },
	// {
	// 	name: "Chargable Area",
	// 	key: "chargable_area",
	// 	input_type: inputTypes.ENTER.key,
	// 	constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
	// 	data: {},
	// 	data_type: datatypes.DECIMAL.key,
	// 	sort_no: 1,
	// 	group: "",
	// 	// option_data: {
	// 	// 	type: arrType,
	// 	// 	list: ZONE_OPTIONS,
	// 	// 	getOptionLabel: (row) => {
	// 	// 		return { name: row.label, key: row.value };
	// 	// 	},
	// 	// },
	// },
	// {
	// 	name: "Facade In Feet",
	// 	key: "facade_in_feet",
	// 	input_type: inputTypes.ENTER.key,
	// 	constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
	// 	data: {},
	// 	data_type: datatypes.DECIMAL.key,
	// 	sort_no: 1,
	// 	group: "",
	// 	// option_data: {
	// 	// 	type: arrType,
	// 	// 	list: ZONE_OPTIONS,
	// 	// 	getOptionLabel: (row) => {
	// 	// 		return { name: row.label, key: row.value };
	// 	// 	},
	// 	// },
	// },
	// {
	// 	name: "Stock Room",
	// 	key: "stock_room",
	// 	input_type: inputTypes.SELECT.key,
	// 	constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
	// 	data: {},
	// 	data_type: datatypes.STRING.key,
	// 	sort_no: 1,
	// 	group: "",
	// 	option_data: {
	// 		type: arrType,
	// 		list: STOCK_ROOM_OPTIONS,
	// 	},
	// },
	// {
	// 	name: "Stock Room IN (SQ Feet)",
	// 	key: "stock_room_in_sq_feet",
	// 	input_type: inputTypes.ENTER.key,
	// 	constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
	// 	data: {},
	// 	data_type: datatypes.STRING.key,
	// 	sort_no: 1,
	// 	group: "",
	// },
	// {
	// 	name: "Stock Room OUT (SQ Feet)",
	// 	key: "stock_room_out_sq_feet",
	// 	input_type: inputTypes.ENTER.key,
	// 	constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
	// 	data: {},
	// 	data_type: datatypes.STRING.key,
	// 	sort_no: 1,
	// 	group: "",
	// },
	// {
	// 	name: "Trading Area",
	// 	key: "trading_area",
	// 	input_type: inputTypes.SELECT.key,
	// 	constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
	// 	data: {},
	// 	data_type: datatypes.STRING.key,
	// 	sort_no: 1,
	// 	group: "",
	// 	option_data: {
	// 		type: arrType,
	// 		list: STOCK_ROOM_OPTIONS,
	// 	},
	// },
	// {
	// 	name: "Trading Area (SQ Feet)",
	// 	key: "trading_area_sq_feet",
	// 	input_type: inputTypes.ENTER.key,
	// 	constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
	// 	data: {},
	// 	data_type: datatypes.DECIMAL.key,
	// 	sort_no: 1,
	// 	group: "",
	// },
];

export const area_details = [
	{
		name: "Floor Number",
		key: "floor_no",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		// option_data: {
		// 	type: arrType,
		// 	list: ZONE_OPTIONS,
		// 	getOptionLabel: (row) => {
		// 		return { name: row.label, key: row.value };
		// 	},
		// },
	},
	{
		name: "Carpet Area",
		key: "carpet_area",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.DECIMAL.key,
		sort_no: 1,
		group: "",
		// option_data: {
		// 	type: arrType,
		// 	list: ZONE_OPTIONS,
		// 	getOptionLabel: (row) => {
		// 		return { name: row.label, key: row.value };
		// 	},
		// },
	},
	{
		name: "Chargable Area",
		key: "chargable_area",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.DECIMAL.key,
		sort_no: 1,
		group: "",
		// option_data: {
		// 	type: arrType,
		// 	list: ZONE_OPTIONS,
		// 	getOptionLabel: (row) => {
		// 		return { name: row.label, key: row.value };
		// 	},
		// },
	},
	{
		name: "Facade In Feet",
		key: "facade_in_feet",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.DECIMAL.key,
		sort_no: 1,
		group: "",
		// option_data: {
		// 	type: arrType,
		// 	list: ZONE_OPTIONS,
		// 	getOptionLabel: (row) => {
		// 		return { name: row.label, key: row.value };
		// 	},
		// },
	},
	{
		name: "Stock Room",
		key: "stock_room",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		option_data: {
			type: arrType,
			list: STOCK_ROOM_OPTIONS,
		},
	},
	{
		name: "Stock Room IN (SQ Feet)",
		key: "stock_room_in_sq_feet",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Stock Room OUT (SQ Feet)",
		key: "stock_room_out_sq_feet",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Trading Area",
		key: "trading_area",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		option_data: {
			type: arrType,
			list: STOCK_ROOM_OPTIONS,
		},
	},
	{
		name: "Trading Area (SQ Feet)",
		key: "trading_area_sq_feet",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.DECIMAL.key,
		sort_no: 1,
		group: "",
	},
];

export const autoUpdateStoreBasicDetailsData = {
	pincode: {
		col: "state",
		val: async (triggerCol, triggerColVal, colToUpdate, options) => {
			if (triggerColVal.length > 5) {
				const res = await axios.get(`/api/store/get-state/${triggerColVal}`);
				if (res.status === 200 && res.data[0].state) {
					return res.data[0].state;
				} else {
					return "";
				}
			} else {
				return "";
			}
		},
	},
};

export const leaseDetails = [
	{
		name: "Store Opening Date",
		key: "store_opening_date",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.DATE.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Financial Year",
		key: "financial_year",
		input_type: inputTypes.AUTO_GENERATE.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.NUMBER.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Rental Start Date",
		key: "rental_start_date",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.DATE.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Fitout Start Date",
		key: "fitout_start_date",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.DATE.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Fitout End Date",
		key: "fitout_end_date",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.DATE.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Fitout Period",
		key: "fitout_period",
		input_type: inputTypes.AUTO_GENERATE.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Lease Term",
		key: "lease_term",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Lease Start Date",
		key: "lease_start_date",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.DATE.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Lease End Date",
		key: "lease_end_date",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.DATE.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Lease Renewal",
		key: "lease_reneval",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		option_data: {
			type: arrType,
			list: monthStringCount,
			getOptionLabel: (row) => {
				return { name: row, key: row };
			},
		},
	},
	{
		name: "Lease Renewal Date",
		key: "lease_reneval_date",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.DATE.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Lease Renewal Notice",
		key: "lease_reneval_notice",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		option_data: {
			type: arrType,
			list: monthStringCount,
			getOptionLabel: (row) => {
				return { name: row, key: row };
			},
		},
	},

	{
		name: "Lease Escalation",
		key: "lease_escalation",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Lease Escalation Date",
		key: "lease_escalation_date",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.DATE.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Notice Period",
		key: "notice_period",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		option_data: {
			type: arrType,
			list: monthStringCount,
			getOptionLabel: (row) => {
				return { name: row, key: row };
			},
		},
	},
	{
		name: "Lock-In Period",
		key: "lock_in_period",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		option_data: {
			type: arrType,
			list: monthStringCount,
			getOptionLabel: (row) => {
				return { name: row, key: row };
			},
		},
	},
	{
		name: "Lock-In Applicable Date",
		key: "lock_in_applicable_date",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.DATE.key,
		sort_no: 1,
		group: "",
	},

	{
		name: "Revenue Share",
		key: "revenue_share",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Marketing Charges Per Month",
		key: "marketing_charges_per_month",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.DECIMAL.key,
		sort_no: 1,
		group: "",
		// option_data: {
		// 	type: arrType,
		// 	list: ZONE_OPTIONS,
		// 	getOptionLabel: (row) => {
		// 		return { name: row.label, key: row.value };
		// 	},
		// },
	},
	{
		name: "Gift Vouchers per month",
		key: "gift_vouchers_per_month",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.DECIMAL.key,
		sort_no: 1,
		group: "",
		// option_data: {
		// 	type: arrType,
		// 	list: ZONE_OPTIONS,
		// 	getOptionLabel: (row) => {
		// 		return { name: row.label, key: row.value };
		// 	},
		// },
	},
	// {
	// 	name: "Electricity Deposit",
	// 	key: "electricity_deposit",
	// 	input_type: inputTypes.ENTER.key,
	// 	constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
	// 	data: {},
	// 	data_type: datatypes.STRING.key,
	// 	sort_no: 1,
	// 	group: "",
	// },
	// {
	// 	name: "Rent Escalation",
	// 	key: "rent_escalation",
	// 	input_type: inputTypes.ENTER.key,
	// 	constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
	// 	data: {},
	// 	data_type: datatypes.STRING.key,
	// 	sort_no: 1,
	// 	group: "",
	// },
	// {
	// 	name: "Rent Escalation Date",
	// 	key: "rent_escalation_date",
	// 	input_type: inputTypes.ENTER.key,
	// 	constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
	// 	data: {},
	// 	data_type: datatypes.DATE.key,
	// 	sort_no: 1,
	// 	group: "",
	// },
];

export const billerDetailsTableColumns = [
	{
		name: "Effective Date",
		key: "effective_date",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.DATE.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Contact Person Name",
		key: "contact_person_name",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Contact Phone No",
		key: "contact_phone_no",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.NUMBER.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Email Id",
		key: "email_id",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Biller Bank Name",
		key: "bank_name",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Biller Bank Account No",
		key: "bank_account_no",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	{
		name: " Bank IFSC Code",
		key: "ifsc_code",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "GST Number",
		key: "gst_no",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Pan Number",
		key: "pan_no",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Name As Per Agreement",
		key: "name_as_per_agreement",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	// {
	// 	name: "Name As Per Account",
	// 	key: "name_as_per_acc",
	// 	input_type: inputTypes.ENTER.key,
	// 	constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
	// 	data: {},
	// 	data_type: datatypes.STRING.key,
	// 	sort_no: 1,
	// 	group: "",
	// },
	// {
	// 	name: "Name Of Franchise Company",
	// 	key: "name_of_franchise_company",
	// 	input_type: inputTypes.ENTER.key,
	// 	constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
	// 	data: {},
	// 	data_type: datatypes.STRING.key,
	// 	sort_no: 1,
	// 	group: "",
	// },
	// {
	// 	name: "Name Of Franchise Company",
	// 	key: "name_of_franchise_company",
	// 	input_type: inputTypes.ENTER.key,
	// 	constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
	// 	data: {},
	// 	data_type: datatypes.STRING.key,
	// 	sort_no: 1,
	// 	group: "",
	// },
];

export const selectedStoreDummyData = {
	store_code: "RR001",
	brand: "RARE RABBIT",
	[storeLeaseAgreeAssocObj]: {
		...leaseDetails.reduce((acc, row) => {
			acc[row.key] = row;
			return acc;
		}, {}),
	},
};

export const eboLiscenceTableColumns = [
	{
		name: "Issue Date",
		key: "issue_date",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.DATE.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Expiry Date",
		key: "expiry_date",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.DATE.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Type",
		key: "type",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		option_data: {
			type: arrType,
			list: storeLiscenceTypes,
			getOptionLabel: (row) => {
				return { name: row.label, key: row.value };
			},
		},
	},
	{
		name: "Liscence Number",
		key: "liscence_no",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
];

export const storeCamTableColumns = [
	{
		name: "Start Date",
		key: "start_date",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.DATE.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "End Date",
		key: "end_date",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.DATE.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "CAM",
		key: "cam",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.DECIMAL.key,
		sort_no: 1,
		group: "",
	},
];
export const storeRentTableColumns = [
	{
		name: "Start Date",
		key: "start_date",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.DATE.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "End Date",
		key: "end_date",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.DATE.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Rent",
		key: "rent",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.DECIMAL.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Revenue Share",
		key: "revenue_share",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.DECIMAL.key,
		sort_no: 1,
		group: "",
	},
];

export const storeCapexTableColumns = [
	{
		name: "Actual Capex",
		key: "actual_apex",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.DECIMAL.key,
		sort_no: 1,
		group: "",
		// option_data: {
		// 	type: arrType,
		// 	list: storeLiscenceTypes,
		// 	getOptionLabel: (row) => {
		// 		return { name: row.label, key: row.value };
		// 	},
		// },
	},
	{
		name: "Projected Capex",
		key: "projected_capex",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.DECIMAL.key,
		sort_no: 1,
		group: "",
		// option_data: {
		// 	type: arrType,
		// 	list: storeLiscenceTypes,
		// 	getOptionLabel: (row) => {
		// 		return { name: row.label, key: row.value };
		// 	},
		// },
	},
	{
		name: "Capex Sell Value",
		key: "capex_sell_value",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		// option_data: {
		// 	type: arrType,
		// 	list: storeLiscenceTypes,
		// 	getOptionLabel: (row) => {
		// 		return { name: row.label, key: row.value };
		// 	},
		// },
	},

	{
		name: "Capex Document",
		key: "capex_document",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.FILE.key,
		sort_no: 1,
		group: "",
		// option_data: {
		// 	type: arrType,
		// 	list: storeLiscenceTypes,
		// 	getOptionLabel: (row) => {
		// 		return { name: row.label, key: row.value };
		// 	},
		// },
	},
	{
		name: "Board Resolution No",
		key: "board_resolution_no",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Board Resolution Date",
		key: "board_resolution_date",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: false, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.DATE.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Board Resolution Document",
		key: "board_resolution_document",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.FILE.key,
		sort_no: 1,
		group: "",
		// option_data: {
		// 	type: arrType,
		// 	list: storeLiscenceTypes,
		// 	getOptionLabel: (row) => {
		// 		return { name: row.label, key: row.value };
		// 	},
		// },
	},
	{
		name: "Capex PO File",
		key: "capex_po_file",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.FILE.key,
		sort_no: 1,
		group: "",
		// option_data: {
		// 	type: arrType,
		// 	list: storeLiscenceTypes,
		// 	getOptionLabel: (row) => {
		// 		return { name: row.label, key: row.value };
		// 	},
		// },
	},
	{
		name: "Capex Actual Invoice",
		key: "capex_actual_invoice",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.FILE.key,
		sort_no: 1,
		group: "",
		// option_data: {
		// 	type: arrType,
		// 	list: storeLiscenceTypes,
		// 	getOptionLabel: (row) => {
		// 		return { name: row.label, key: row.value };
		// 	},
		// },
	},

	{
		name: "Gst registered",
		key: "gst_registered",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		option_data: {
			type: arrType,
			list: GST_REGISTERED_OPTIONS,
			getOptionLabel: (row) => {
				return { name: row.label, key: row.value };
			},
		},
	},
	{
		name: "Gst Number",
		key: "gst_no",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	// {
	// 	name: "Rent Shedule",
	// 	key: "rent_shedule",
	// 	input_type: inputTypes.SELECT.key,
	// 	constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
	// 	data: {},
	// 	data_type: datatypes.STRING.key,
	// 	sort_no: 1,
	// 	group: "",
	// },
];

export const storeInsuranceTableColumns = [
	{
		name: "Issue Date",
		key: "issue_date",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.DATE.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Expiry Date",
		key: "expiry_date",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.DATE.key,
		sort_no: 1,
		group: "",
	},
	// {
	// 	name: "Category",
	// 	key: "category",
	// 	input_type: inputTypes.SELECT.key,
	// 	constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
	// 	data: {},
	// 	data_type: datatypes.STRING.key,
	// 	sort_no: 1,
	// 	group: "",
	// 	// option_data: {
	// 	// 	type: arrType,
	// 	// 	list: storeLiscenceTypes,
	// 	// 	getOptionLabel: (row) => {
	// 	// 		return { name: row.label, key: row.value };
	// 	// 	},
	// 	// },
	// },
	// {
	// 	name: "Insurance Number",
	// 	key: "liscence_no",
	// 	input_type: inputTypes.ENTER.key,
	// 	constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
	// 	data: {},
	// 	data_type: datatypes.STRING.key,
	// 	sort_no: 1,
	// 	group: "",
	// },
	{
		name: "Business Suraksha Number",
		key: "bs_insurance_no",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Workmen Insurance Number",
		key: "workmen_comp_no",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	// {
	// 	name: "Insurance Premium Amount",
	// 	key: "insurance_premium_amount",
	// 	input_type: inputTypes.ENTER.key,
	// 	constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
	// 	data: {},
	// 	data_type: datatypes.NUMBER.key,
	// 	sort_no: 1,
	// 	group: "",
	// },
	{
		name: "BS Premium Amount",
		key: "bs_premium_amount",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.NUMBER.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Workmen Premium Amount",
		key: "workmen_premium_amount",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.NUMBER.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Insured Stock Amount",
		key: "insured_product_amount",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.NUMBER.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Insured Capex Amount",
		key: "insured_capex_amount",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.NUMBER.key,
		sort_no: 1,
		group: "",
	},
	// {
	// 	name: "Insurance Insured value",
	// 	key: "insurance_insured_value",
	// 	input_type: inputTypes.ENTER.key,
	// 	constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
	// 	data: {},
	// 	data_type: datatypes.NUMBER.key,
	// 	sort_no: 1,
	// 	group: "",
	// },
	{
		name: "Money In Safe",
		key: "money_in_safe",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: false, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.NUMBER.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Money In Transit",
		key: "money_in_transit",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: false, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.NUMBER.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Money At Counter",
		key: "money_at_counter",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: false, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.NUMBER.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Public Liability",
		key: "public_liability",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: false, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.NUMBER.key,
		sort_no: 1,
		group: "",
	},
];

export const storeDepositTableColumns = [
	{
		name: "Date",
		key: "date",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.DATE.key,
		sort_no: 1,
		group: "",
	},

	{
		name: "Type",
		key: "deposit_type",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		option_data: {
			type: arrType,
			list: storeDepositTypes,
			getOptionLabel: (row) => {
				return { name: row.label, key: row.value };
			},
		},
	},
	{
		name: "Milestone",
		key: "milestone",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		option_data: {
			type: arrType,
			list: storeDepositMilestones,
			getOptionLabel: (row) => {
				return { name: row.label, key: row.value };
			},
		},
	},
	{
		name: "Deposit Value",
		key: "deposit_value",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.NUMBER.key,
		sort_no: 1,
		group: "",
	},
];

var removeSubfolders = function (folder) {
	let obj = {};
	let resArr = [];
	for (let i = 0; i < folder.length; i++) {
		if (obj[folder[i]]) return;
		else {
			let key = "";
			for (let j = 0; j < folder[i].length; j++) {
				key[j] = folder[i][j];
				if (folder[i][j] === "/" && obj[key]) break;
			}
			if (key === folder[i]) resArr.push(folder[i]);
		}
	}
	return resArr;
};

export const storesLinkedTables = {
	// sync with backend data
	store: { name: "Store", key: "store", sqlTable: "store", seqMethod: "store" },
	store_basic_details: {
		name: "Store Basic Details",
		key: "store_basic_details",
		sqlTable: "store_basic_details",
		seqMethod: "storeBasicDetails",
	},
	store_type_details: {
		name: "Store Type Details",
		key: "store_type_details",
		sqlTable: "store_type_details",
		seqMethod: "storeTypeDetails",
	},
	store_biller_details: {
		name: "Store Biller Details",
		key: "store_biller_details",
		sqlTable: "store_biller_details",
		seqMethod: "storeBillerDetails",
	},
	store_deposit_details: {
		name: "Store Deposit Details",
		key: "store_deposit_details",
		sqlTable: "store_deposit_details",
		seqMethod: "storeDepositDetails",
	},
	store_lease_agreement_details: {
		name: "Store Lease Agreement Details",
		key: "store_lease_agreement_details",
		sqlTable: "store_lease_agreement_details",
		seqMethod: "storeLeaseAgreementDetails",
	},
	store_capex_details: {
		name: "Store Capex Details",
		key: "store_capex_details",
		sqlTable: "store_capex_details",
		seqMethod: "storeCapexDetails",
	},
	store_insurance_details: {
		name: "Store Insurance Details",
		key: "store_insurance_details",
		sqlTable: "store_insurance_details",
		seqMethod: "storeInsuranceDetails",
	},
	store_liscence_details: {
		name: "Store Liscence Details",
		key: "store_liscence_details",
		sqlTable: "store_liscence_details",
		seqMethod: "storeLiscenceDetails",
	},
	store_cam_details: {
		name: "Store Cam Details",
		key: "store_cam_details",
		sqlTable: "store_cam_details",
		seqMethod: "storeCamDetails",
	},
	store_rent_and_revenue_share_details: {
		name: "Store Rent And Revenue Share Details",
		key: "store_rent_and_revenue_share_details",
		sqlTable: "store_rent_and_revenue_share_details",
		seqMethod: "storeRentAndRevenueShareDetails",
	},
	store_renovation_details: {
		name: "Store Renovation Details",
		key: "store_renovation_details",
		sqlTable: "store_renovation_details",
		seqMethod: "storeRenovationDetails",
	},
	store_area_details: {
		name: "Store Area Details",
		key: "store_area_details",
		sqlTable: "area_details",
		seqMethod: "storeAreaDetails",
	},
	files: {
		name: "Files",
		key: "files",
		sqlTable: "store_files_details",
		seqMethod: "storeFilesDetails",
	},
	employee_details: {
		name: "Empolyee Details",
		key: "employee_details",
		sqlTable: "store_employee_details",
		seqMethod: "storeEmployeeDetails",
	},
	sales_projection_details: {
		name: "Sales Projection Details",
		key: "sales_projection_details",
		sqlTable: "store_sales_projection_details",
		seqMethod: "storeSalesProjectionDetails",
	},
};

export const fileTypeColumns = [
	{
		name: "Pancard",
		key: "PANCARD",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.FILE.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "GST Certificate",
		key: "GST_CERT",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.FILE.key,
		sort_no: 2,
		group: "",
	},
	{
		name: "Blank Cheque Photo Copy",
		key: "BLANK_CHEQUE_PHOTO",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.FILE.key,
		sort_no: 3,
		group: "",
	},
];
export const leaseAgreementTableColumns = [
	{
		name: "Lease Agreement",
		key: "lease_agreement",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.FILE.key,
		sort_no: 1,
		group: "",
	},
];
export const insuranceTableColumns = [
	// {
	// 	name: "Insurance Document",
	// 	key: "insurance_document",
	// 	input_type: inputTypes.SELECT.key,
	// 	constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
	// 	data: {},
	// 	data_type: datatypes.FILE.key,
	// 	sort_no: 1,
	// 	group: "",
	// },
	{
		name: "Business Suraksha",
		key: "business_suraksha",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.FILE.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Workmen Compensation",
		key: "workmen_compensation",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.FILE.key,
		sort_no: 1,
		group: "",
	},
];
export const liscenceTableColumns = [
	{
		name: "Liscence Document",
		key: "liscence_document",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.FILE.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "MAll / HS Presentation",
		key: "mall_hs_presentation",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.FILE.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Store Feasibility Report",
		key: "store_feasibility_report",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.FILE.key,
		sort_no: 1,
		group: "",
	},
];

export const insuranceTypeColumns = [
	{
		name: "Goods Insurance",
		key: "goods_insurance",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.FILE.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Capex  Insurance",
		key: "capex_insurance",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.FILE.key,
		sort_no: 2,
		group: "",
	},
];

export const storeRenovationTableColumns = [
	{
		name: "Start Date",
		key: "start_date",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.DATE.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "End Date",
		key: "end_date",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.DATE.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Renovation Amount",
		key: "renovation_amount",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.NUMBER.key,
		sort_no: 1,
		group: "",
	},
];

export const storeCapexTableOptions = [
	{
		name: "Advance",
		key: "advance",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Month End",
		key: "month_end",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
];

export const fileUploadTableOptions = [
	{
		name: "Shop & Est / Labour",
		key: "shop_est_labour",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "LOI / Term Sheet",
		key: "loi_term_sheet",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Trade License",
		key: "trade_license",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Floor Plan",
		key: "floor_plan",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Music License",
		key: "music_license",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Fire Safety",
		key: "fire_safety",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
];

export const employeeDetailsTableColumns = [
	{
		name: "Floor",
		key: "floor_no",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Brand",
		key: "brand",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 2,
		group: "",
	},
	{
		name: "Role",
		key: "role",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 3,
		group: "",
	},
	{
		name: "Count",
		key: "count",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.NUMBER.key,
		sort_no: 4,
		group: "",
	},
	{
		name: "Budgeted CTC",
		key: "budgeted_ctc",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.NUMBER.key,
		sort_no: 5,
		group: "",
	},
	// {
	// 	name: "Trading Area",
	// 	key: "trading_area",
	// 	input_type: inputTypes.AUTO_GENERATE.key,
	// 	constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
	// 	data: {},
	// 	data_type: datatypes.DECIMAL.key,
	// 	sort_no: 6,
	// 	group: "",
	// },
	// {
	// 	name: "Stock Room Area",
	// 	key: "stock_room_area",
	// 	input_type: inputTypes.AUTO_GENERATE.key,
	// 	constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
	// 	data: {},
	// 	data_type: datatypes.DECIMAL.key,
	// 	sort_no: 6,
	// 	group: "",
	// },
];

export const salesProjectionDetailsColumns = [
	{
		name: "Year",
		key: "year",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: false, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Month",
		key: "month",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 2,
		group: "",
	},
	{
		name: "Brand",
		key: "brand",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 3,
		group: "",
	},
	{
		name: "Projected Sales",
		key: "projected_sales",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.NUMBER.key,
		sort_no: 4,
		group: "",
	},
	{
		name: "Incentive",
		key: "incentive",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: false, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.DECIMAL.key,
		sort_no: 5,
		group: "",
	},
];

export const salesEmployeeRoleColumns = [
	{
		name: "STORE MANAGER",
		key: "store_manager",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "ASST. STORE MANAGER",
		key: "asst_store_manager",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 2,
		group: "",
	},
	{
		name: "FC",
		key: "fc",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 3,
		group: "",
	},
	{
		name: "STOCK BOY",
		key: "stock_boy",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 4,
		group: "",
	},
	{
		name: "HK",
		key: "hk",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 5,
		group: "",
	},
	{
		name: "OMNI",
		key: "omni",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 5,
		group: "",
	},
	{
		name: "AREA SALES MANAGER",
		key: "area_sales_manager",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 5,
		group: "",
	},
];
