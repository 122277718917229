import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { eboAllStorespageUrl } from "./data";

function EboStoresHome() {
	const navigate = useNavigate();

	return (
		<Box direction="row" width="100%" justifyContent="space-around" flexWrap="wrap" p={2} boxSizing={"border-box"}>
			<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} pt={2}>
				<Typography variant="h4" align={"left"}>
					EBO STORES SUMMARY
				</Typography>
				<Button autoFocus variant="contained" onClick={() => navigate(eboAllStorespageUrl)}>
					VIEW ALL STORES
				</Button>
			</Stack>
		</Box>
	);
}

export default EboStoresHome;
