import * as React from "react";
import { Box, Button, ClickAwayListener, Paper, Stack, Typography } from "@mui/material";
import axios from "axios";
import { GlobalState } from "context/GlobalContext";
import { MyntraRecoState } from "context/MyntraReco";
import { useEffect, useState } from "react";
import StartDateEndDateCopm from "../dateComponent";
import { grey } from "@mui/material/colors";
import { AmountConverter } from "components/ReusableComponents/InLakhsDisplay";
import { DataGrid } from "@mui/x-data-grid";
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart } from "@mui/x-charts/PieChart";
import "../myntraReco.css";
import { pythonVmInstance } from "utils/AxiosInstances/RareboxPythonInstance";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import FileSaver from "file-saver";

const chipesData = [
	{
		name: "Forward Settlement",
		myntra_amt: "forward_settled_amt",
		realised_value: "forward_expected_settlement_amt",
		add: false,
	},
	{
		name: "Reverse Settlement",
		myntra_amt: "reverse_settled_amt",
		realised_value: "reverse_expected_settlement_amt",
		add: true,
	},
];
const totalSales = [
	{
		name: "Total Sales",
		sales: "total_sales_amt",
		return: "returned_product_amt",
		sales_count: "total_sales",
		return_count: "returned_product",
	},
];

const totalData = [
	{
		name: "Total Order Status",
		closed: "closed_count",
		open: "open_count",
		add: true,
	},
];
const summaryData = [
	{
		name: "Total Ginesy Sales",
		qty: "total_sales",
		realised_value: "total_sales_amt",
	},
	{
		name: "Forward Settled",
		qty: "forward_count",
		realised_value: "forward_settled_amt",
	},
	{
		name: "Forward Expected",
		qty: "forward_count",
		realised_value: "forward_expected_settlement_amt",
	},
	{
		name: "Reverse Settled",
		qty: "reverse_count",
		realised_value: "reverse_settled_amt",
	},
	{
		name: "Reverse Expected",
		qty: "reverse_count",
		realised_value: "reverse_expected_settlement_amt",
	},
	{
		name: "Returned Products",
		qty: "returned_product",
		realised_value: "returned_product_amt",
	},
	{
		name: "SPF",
		qty: "spf_count",
		realised_value: "spf_amt",
	},
	// {
	// 	name: "Total",
	// 	qty: "sales_count",
	// 	realised_value: "total_realised_amt",
	// },
	{
		name: "NOV Credit",
		qty: "",
		realised_value: "total_credit",
	},
	{
		name: "NOV Dredit",
		qty: "",
		realised_value: "total_debit",
	},
];

const MyntraOmniDashboard = () => {
	const { dashboardData, setDashboardData } = MyntraRecoState();
	const { setSnackBar, snackBar, setSnackBarOpen, loginUser, setLoadingOpen } = GlobalState();
	const [data, setData] = useState([]);
	const [allData, setAllData] = useState([]);
	const [nonOrderData, setnonOrderData] = useState([]);
	const [receivableAging, setReceivableAging] = useState([]);
	const [openTooltip, setOpenTooltip] = useState(false);
	const [tableData, setTableData] = useState([]);
	const [startDate, setStartDate] = useState("2024-01-01");
	const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"));

	const summaryTable = [
		{
			field: "month_year",
			headerName: "Month-Year",
			align: "center",
			headerAlign: "center",
			width: 150,
			renderCell: ({ row }) => {
				return (
					<Typography width="50%" variant="subtitle2" sx={row.month === "" ? { fontWeight: "bold" } : {}}>
						{row.month === "" ? row.year : `${getMonthName(row.year, row.month)}-${row.year}`}
					</Typography>
				);
			},
		},
		{
			field: "forward_settled_amt",
			headerName: "Myntra Forward Settled",
			align: "center",
			headerAlign: "center",
			width: 180,
			renderCell: ({ row }) => {
				return (
					<div>
						<AmountConverter
							number={row.forward_settled_amt.toFixed(2)}
							sx={row.month === "" ? { fontWeight: "bold" } : {}}
						/>
					</div>
				);
			},
		},
		{
			field: "forward_expected_settlement_amt",
			headerName: "Expected Forward",
			align: "center",
			headerAlign: "center",
			width: 160,
			renderCell: ({ row }) => {
				return (
					<div>
						<AmountConverter
							number={row.forward_expected_settlement_amt.toFixed(2)}
							sx={row.month === "" ? { fontWeight: "bold" } : {}}
						/>
					</div>
				);
			},
		},
		{
			field: "forward_diffrence",
			headerName: "Forward Diffrence",
			align: "center",
			headerAlign: "center",
			width: 160,
			renderCell: ({ row }) => {
				return (
					<div>
						<AmountConverter
							number={(row.forward_expected_settlement_amt - row.forward_settled_amt).toFixed(2)}
							sx={row.month === "" ? { fontWeight: "bold" } : {}}
						/>
					</div>
				);
			},
		},
		{
			field: "reverse_settled_amt",
			headerName: "Reverse Settled",
			align: "center",
			headerAlign: "center",
			width: 150,
			renderCell: ({ row }) => {
				return (
					<div>
						<AmountConverter
							number={row.reverse_settled_amt.toFixed(2)}
							sx={row.month === "" ? { fontWeight: "bold" } : {}}
						/>
					</div>
				);
			},
		},
		{
			field: "reverse_expected_settlement_amt",
			headerName: "Expected Reverse",
			align: "center",
			headerAlign: "center",
			width: 150,
			renderCell: ({ row }) => {
				return (
					<div>
						<AmountConverter
							number={row.reverse_expected_settlement_amt.toFixed(2)}
							sx={row.month === "" ? { fontWeight: "bold" } : {}}
						/>
					</div>
				);
			},
		},
		{
			field: "reverse_diffrence",
			headerName: "Reverse Diffrence",
			align: "center",
			headerAlign: "center",
			width: 150,
			renderCell: ({ row }) => {
				return (
					<div>
						<AmountConverter
							number={(row.reverse_settled_amt + row.reverse_expected_settlement_amt).toFixed(2)}
							sx={row.month === "" ? { fontWeight: "bold" } : {}}
						/>
					</div>
				);
			},
		},
		{
			field: "returned_product_amt",
			headerName: "Returned Product",
			align: "center",
			headerAlign: "center",
			width: 150,
			renderCell: ({ row }) => {
				return (
					<div>
						<AmountConverter
							number={row?.returned_product_amt && row?.returned_product_amt.toFixed(2)}
							sx={row.month === "" ? { fontWeight: "bold" } : {}}
						/>
					</div>
				);
			},
		},
		{
			field: "total_spf_net_amt",
			headerName: "SPF Net Amt",
			align: "center",
			headerAlign: "center",
			width: 140,
			renderCell: ({ row }) => {
				return (
					<div>
						<AmountConverter
							number={row.total_spf_net_amt && row.total_spf_net_amt.toFixed(2)}
							sx={row.month === "" ? { fontWeight: "bold" } : {}}
						/>
					</div>
				);
			},
		},
		{
			field: "spf_amt",
			headerName: "SPF",
			align: "center",
			headerAlign: "center",
			width: 140,
			renderCell: ({ row }) => {
				return (
					<div>
						<AmountConverter
							number={row.spf_amt && row.spf_amt.toFixed(2)}
							sx={row.month === "" ? { fontWeight: "bold" } : {}}
						/>
					</div>
				);
			},
		},
	];

	const receivableAgingColumn = [
		{
			field: "period",
			headerName: "Period",
			flex: 1,
			align: "center",
			headerAlign: "center",
			width: 160,
		},
		{
			field: "order_count",
			headerName: "Orders",
			align: "center",
			flex: 1,
			headerAlign: "center",
			width: 140,
			renderCell: ({ row }) => {
				return (
					<div>
						<AmountConverter number={row.order_count} />
					</div>
				);
			},
		},
		{
			field: "total_amount",
			headerName: "Net Amount",
			align: "center",
			flex: 1,
			headerAlign: "center",
			width: 140,
			renderCell: ({ row }) => {
				return (
					<div>
						<AmountConverter number={row.total_amount.toFixed(2)} />
					</div>
				);
			},
		},
		{
			field: "calculated_amount",
			headerName: "Receivable Amount",
			align: "center",
			flex: 1,
			headerAlign: "center",
			width: 170,
			renderCell: ({ row }) => {
				return (
					<div>
						<AmountConverter number={row.calculated_amount.toFixed(2)} />
					</div>
				);
			},
		},
		{
			field: "action",
			headerName: "Action",
			align: "center",
			flex: 1,
			headerAlign: "center",
			width: 180,
			renderCell: ({ row }) => {
				return (
					<Button onClick={() => handleDownloadReport(row)} variant="contained">
						Download
					</Button>
				);
			},
		},
	];

	const handleDownloadReport = (row) => {
		handleReceivableAgingApi(row.period);
	};

	function getMonthName(year, monthNumber) {
		// Create a new Date object with the monthNumber (0-based index)
		const date = new Date(year, monthNumber - 1); // Year is arbitrary
		// Use toLocaleString to get the month name
		return date.toLocaleString("default", { month: "long" });
	}
	function getMonthNameShort(year, monthNumber) {
		// Create a new Date object with the monthNumber (0-based index)
		const date = new Date(year, monthNumber - 1); // Year is arbitrary
		// Use toLocaleString to get the month name
		return date.toLocaleString("default", { month: "short" });
	}

	const addTotalColum = (data) => {
		let total = {
			forward_count: 0,
			forward_expected_settlement_amt: 0,
			forward_settled_amt: 0,
			month: "",
			returned_product: 0,
			returned_product_amt: 0,
			reverse_count: 0,
			reverse_expected_settlement_amt: 0,
			reverse_settled_amt: 0,
			spf_amt: 0,
			spf_count: 0,
			total_sales: 0,
			open_count: 0,
			closed_count: 0,
			total_sales_amt: 0,
			year: "TOTAL",
			month_year: "",
			total_spf_net_amt: 0,
		};
		data.forEach((row) => {
			total["forward_count"] += row["forward_count"];
			total["forward_expected_settlement_amt"] += row["forward_expected_settlement_amt"];
			total["forward_settled_amt"] += row["forward_settled_amt"];
			total["returned_product"] += row["returned_product"];
			total["returned_product_amt"] += row["returned_product_amt"];
			total["reverse_count"] += row["reverse_count"];
			total["reverse_expected_settlement_amt"] += row["reverse_expected_settlement_amt"];
			total["reverse_settled_amt"] += row["reverse_settled_amt"];
			total["spf_amt"] += row["spf_amt"];
			total["spf_count"] += row["spf_count"];
			total["total_sales"] += row["total_sales"];
			total["open_count"] += row["open_count"];
			total["closed_count"] += row["closed_count"];
			total["total_sales_amt"] += row["total_sales_amt"];
			total["total_spf_net_amt"] += row["total_spf_net_amt"];
		});
		setTableData([{ ...total }, ...data]);
	};

	useEffect(() => {
		handleGetResult();
	}, []);
	const handleGetResult = () => {
		setLoadingOpen(true);
		pythonVmInstance
			.get(`/reco/myntra_omni/get`, {
				params: {
					startDate,
					endDate,
				},
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((res) => {
				let data = res.data.monthWiseData;
				let allData = res.data.allData;
				let nonOrder = res.data.nonOrder;
				let receivableAging = res.data.receivableAging;
				if (data.length === 0) {
					setData([]);
					setAllData([]);
					setnonOrderData([]);
					setReceivableAging([]);
					setSnackBarOpen(true);
					setLoadingOpen(false);
					setSnackBar({
						...snackBar,
						message: "No data to display",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
					return;
				}
				let finalData = [];
				data.forEach((monthData) => {
					nonOrder.forEach((novData) => {
						if (novData.month === monthData.month && novData.year === monthData.year) {
							finalData.push({ ...monthData, total_credit: novData.total_credit, total_debit: novData.total_debit });
						}
					});
				});
				// console.log("finalData", finalData);
				setData(finalData);
				setAllData(allData);
				setnonOrderData(nonOrder);
				//sorting the period
				receivableAging.sort((a, b) => {
					const aNum = parseInt(a.period);
					const bNum = parseInt(b.period);

					// Check if both are numbers or both are not numbers
					if (!isNaN(aNum) && !isNaN(bNum)) {
						return aNum - bNum;
					}

					// If only a is not a number, push it last
					if (isNaN(aNum)) return 1;
					// If only b is not a number, push it last
					if (isNaN(bNum)) return -1;

					return 0;
				});
				setReceivableAging(receivableAging);
				addTotalColum(data);
				setLoadingOpen(false);
			})

			.catch((err) => {
				setSnackBarOpen(true);
				setLoadingOpen(false);
				setSnackBar({
					...snackBar,
					message: "Unable to get summary Data",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			});
	};
	const handleReceivableAgingApi = (period) => {
		setLoadingOpen(true);
		pythonVmInstance
			.get(`/reco/myntra_omni/receivable_report`, {
				params: {
					startDate,
					endDate,
					period: period,
				},
				headers: {
					"Content-Type": "application/json",
				},
				method: "GET",
				responseType: "blob",
			})
			.then((res) => {
				const url = URL.createObjectURL(res.data);
				let selectedPeriod = period.replace(" ", "_");
				FileSaver.saveAs(url, `${startDate}_${endDate}_${selectedPeriod}_receivable_aging_report.csv`);
				setLoadingOpen(false);
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "Downloaded the file",
					severity: "success",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			})

			.catch((err) => {
				setSnackBarOpen(true);
				setLoadingOpen(false);
				setSnackBar({
					...snackBar,
					message: "Unable to Downloaded the file",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			});
	};
	let salesReturn = [];
	let sales = [];
	let returned = [];
	let forwardSales = [];
	let reversed = [];
	let openClosed = [];
	let artical = [];
	let Zonal = [];
	let credit = [];
	let debit = [];
	let creditDebit = [];
	let logistics = [];
	let brand = [];

	// For Sales and Return chart data bind
	data.length > 0 &&
		data.forEach((items) => {
			salesReturn.push(`${getMonthNameShort(items.year, items.month)}-${items.year.toString().substring(2, 5)}`);
			sales.push(items.total_sales);
			returned.push(items.returned_product);
		});
	// Logesticts
	data.length > 0 &&
		data.forEach((items) => {
			salesReturn.push(`${getMonthNameShort(items.year, items.month)}-${items.year.toString().substring(2, 5)}`);
			logistics.push(items.total_logistic / 100000);
		});

	// non order settlement
	nonOrderData.length > 0 &&
		nonOrderData.forEach((items) => {
			creditDebit.push(`${getMonthNameShort(items.year, items.month)}-${items.year.toString().substring(2, 5)}`);
			credit.push(parseInt(items.total_credit / 100000));
			debit.push(parseInt(Math.abs(items.total_debit / 100000)));
		});
	// For Forward sales
	allData.length > 0 &&
		allData.forEach((item) => {
			let total =
				item.e_forward_amt +
				item.e_tcs_amt +
				item.e_tds_amt +
				item.e_platform_fee +
				item.e_shipping_fee +
				item.e_fixed_fee +
				item.e_paymentgatway_fee;
			forwardSales.push(
				{ label: "Forwarded", id: 0, value: item.e_forward_amt, total },
				{ label: "TCS", id: 1, value: item.e_tcs_amt, total },
				{ label: "TDS", id: 2, value: item.e_tds_amt, total },
				{ label: "Platform Fee", id: 3, value: item.e_platform_fee, total },
				{ label: "Shipping Fee", id: 4, value: item.e_shipping_fee, total },
				{ label: "Fixed Fee", id: 5, value: item.e_fixed_fee, total },
				{ label: "Payment Gateway Fee", id: 6, value: item.e_paymentgatway_fee, total, color: "orange" }
			);
		});

	//Reverse settled
	allData.length > 0 &&
		allData.forEach((item) => {
			let total = item.e_total_reverse_logistics + item.e_total_forward_logistics + item.e_total_forward_settled_amt;
			// + item.reverse_expected_settlement_amt;
			reversed.push(
				// { label: "Reverse stl", id: 0, value: item.reverse_expected_settlement_amt, total },
				{ label: "Reverse log", id: 1, value: item.e_total_reverse_logistics, total },
				{ label: "Forward log", id: 2, value: item.e_total_forward_logistics, total },
				{ label: "Forward stl", id: 3, value: item.e_total_forward_settled_amt, total }
			);
		});

	// Brand
	allData.length > 0 &&
		allData.forEach((item) => {
			let total = item.rr_count + item.rsm_count;
			brand.push(
				{ label: "Rare Rabbit", id: 1, value: item.rr_count, total },
				{ label: "Rareism", id: 2, value: item.rsm_count, total }
			);
		});

	// For Open nad Closed
	allData.length > 0 &&
		allData.forEach((item) => {
			let total = item.open_count + item.closed_count;
			openClosed.push(
				{ label: "Open", id: 0, value: item.open_count, total: total },
				{ label: "Closed", id: 1, value: item.closed_count, total: total }
			);
		});
	// Artical Level
	allData.length > 0 &&
		allData.forEach((item) => {
			let total = item.artical_1 + item.artical_2;
			artical.push(
				{ label: "Artical 1", id: 0, value: item.artical_1, total: total },
				{ label: "Artical 2", id: 1, value: item.artical_2, total: total }
			);
		});
	//  Zonal
	allData.length > 0 &&
		allData.forEach((item) => {
			let total = item.national_count + item.open_count + item.zonal_count;
			Zonal.push(
				{ label: "National", id: 0, value: item.national_count, total: total },
				{ label: "Local", id: 1, value: item.local_count, total: total },
				{ label: "Zonal", id: 2, value: item.zonal_count, total: total }
			);
		});

	const CustomeTooltip = ({ tooltipData, children, style }) => {
		let total = tooltipData.filter((row) => row.year === "TOTAL");
		return (
			<div style={style}>
				<ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
					<Tooltip
						PopperProps={{
							disablePortal: true,
						}}
						onClose={() => setOpenTooltip(false)}
						open={openTooltip}
						disableFocusListener
						disableHoverListener
						disableTouchListener
						title={tooltipData
							.filter((row) => row.year !== "TOTAL")
							.map((data) => (
								<div style={{ display: "flex", justifyContent: "space-between", width: "190px", fontSize: "12px" }}>
									<div>{`${getMonthName(data.year, data.month)}-${data.year}`}</div>
									<div>{`${data.open_count}, (${((data.open_count / total[0].open_count) * 100).toFixed(2)}%)`}</div>
								</div>
							))}
						arrow>
						<Button sx={{ width: "140px" }} onClick={() => setOpenTooltip(!openTooltip)}>
							{children}
						</Button>
					</Tooltip>
				</ClickAwayListener>
			</div>
		);
	};

	console.log("tableData", tableData);
	return (
		<div>
			<Typography variant="h5" width="100%" pt={2} pb={2}>
				MYNTRA OMNI DASHBOARD
			</Typography>
			<StartDateEndDateCopm
				setStartDate={(e) => setStartDate(e)}
				startDate={startDate}
				endDate={endDate}
				setEndDate={(e) => setEndDate(e)}
				handleGetResult={() => handleGetResult()}
				disabled={startDate.length === 0 || endDate.length === 0 ? true : false}
				min="2024-01-01"
			/>
			<Box display="flex" rowGap={5} mb={2} columnGap="2%" width="100%" flexWrap="wrap">
				{data.length > 0 &&
					totalSales.map((items) => (
						<Paper sx={{ minWidth: "35%", padding: 1 }} elevation={3}>
							<Typography
								display={"flex"}
								alignItems="center"
								justifyContent="center"
								variant="subtitle1"
								fontWeight="bold"
								color={grey[700]}
								textAlign="left">
								{items.name}
							</Typography>
							<Box p={1} sx={{ display: "flex", justifyContent: "space-between" }}>
								<Typography
									width="27%"
									variant="subtitle2"
									color={grey[600]}
									display="flex"
									alignItems="center"
									justifyContent="center">
									Sales
								</Typography>
								<Typography
									width="50%"
									variant="subtitle2"
									color={grey[600]}
									textAlign="center"
									display="flex"
									alignItems="center"
									justifyContent="center">
									Return
								</Typography>
								<Typography
									width="30%"
									variant="subtitle2"
									color={grey[600]}
									textAlign="center"
									display="flex"
									alignItems="center"
									justifyContent="center">
									Net Sales
								</Typography>
							</Box>
							{tableData
								.filter((data) => data.year === "TOTAL")
								.map((row) => {
									let amt = 0;
									let count = 0;
									let greater = false;
									amt = Math.abs(row[items.sales]) - Math.abs(row[items.return]);
									count = Math.abs(row[items.sales_count]) - Math.abs(row[items.return_count]);
									if (amt > 0) greater = true;
									console.log("sdfghjk", row);
									return (
										<Box p="5px" key={row.key} style={{ display: "flex", justifyContent: "space-between" }}>
											<Typography
												width="27%"
												display="flex"
												flexDirection="column"
												alignItems="center"
												justifyContent="center">
												<AmountConverter number={row[items.sales].toFixed(2)} />
												<AmountConverter number={row[items.sales_count].toFixed(2)} />
											</Typography>
											<Typography
												// width="26%"
												display="flex"
												fontWeight="bold"
												width="50%"
												flexDirection="column"
												alignItems="center"
												justifyContent="center"
												color="red">
												<div style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
													<AmountConverter number={Math.abs(row[items.return]).toFixed(2)} />
													<div>{` , (${((Math.abs(row[items.return]) / row[items.sales]) * 100).toFixed(
														2
													)}%)`}</div>
												</div>
												<div style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
													<AmountConverter number={row[items.return_count].toFixed(2)} />
													<div>{` , (${(
														(Math.abs(row[items.return_count]) / row[items.sales_count]) *
														100
													).toFixed(2)}%)`}</div>
												</div>
											</Typography>
											<Typography
												display="flex"
												alignItems="center"
												justifyContent="center"
												fontWeight="bold"
												minWidth="30%"
												flexDirection="column"
												color={greater ? "green" : "red"}>
												<AmountConverter number={amt.toFixed(2)} />
												<AmountConverter number={count.toFixed(2)} />
											</Typography>
											{/* <Divider /> */}
										</Box>
									);
								})}
						</Paper>
					))}
				{data.length > 0 &&
					chipesData.map((items) => (
						<Paper sx={{ minWidth: "35%", padding: 1 }} elevation={3}>
							<Typography
								display={"flex"}
								alignItems="center"
								justifyContent="center"
								variant="subtitle1"
								fontWeight="bold"
								color={grey[700]}
								textAlign="left">
								{items.name}
							</Typography>
							<Box p={1} sx={{ display: "flex", justifyContent: "space-between" }}>
								<Typography
									width="27%"
									variant="subtitle2"
									color={grey[600]}
									display="flex"
									alignItems="center"
									justifyContent="center">
									Myntra
								</Typography>
								<Typography
									width="26%"
									variant="subtitle2"
									color={grey[600]}
									display="flex"
									alignItems="center"
									justifyContent="center">
									Excepted
								</Typography>
								<Typography
									width="40%"
									variant="subtitle2"
									color={grey[600]}
									display="flex"
									alignItems="center"
									justifyContent="center">
									Diffrence
								</Typography>
							</Box>
							{tableData
								.filter((data) => data.year === "TOTAL")
								.map((row) => {
									let amt = 0;
									let greater = false;
									if (items.add) {
										amt = Math.abs(row[items.myntra_amt]) - row[items.realised_value];
									} else {
										amt = row[items.realised_value] - row[items.myntra_amt];
									}
									if (amt > 0) greater = true;
									return (
										<Box p="5px" key={row.key} style={{ display: "flex", justifyContent: "space-between" }}>
											<Typography width="27%" display="flex" alignItems="center" justifyContent="center">
												<AmountConverter number={Math.abs(row[items.myntra_amt]).toFixed(2)} />
											</Typography>
											<Typography width="26%" display="flex" alignItems="center" justifyContent="center">
												<AmountConverter number={row[items.realised_value].toFixed(2)} />
											</Typography>
											<Typography
												display="flex"
												alignItems="center"
												justifyContent="center"
												fontWeight="bold"
												width="40%"
												color={greater ? "green" : "red"}>
												<AmountConverter number={amt.toFixed(2)} />
												<div>{` , (${((Math.abs(amt) / row[items.realised_value]) * 100).toFixed(2)}%)`}</div>
											</Typography>
											{/* <Divider /> */}
										</Box>
									);
								})}
						</Paper>
					))}
				{data.length > 0 &&
					totalData.map((items) => (
						<Paper sx={{ minWidth: "35%", padding: 1 }} elevation={3}>
							<Typography
								display={"flex"}
								alignItems="center"
								justifyContent="center"
								variant="subtitle1"
								fontWeight="bold"
								color={grey[700]}
								textAlign="left">
								{items.name}
							</Typography>
							<Box p={1} sx={{ display: "flex", justifyContent: "space-between" }}>
								<Typography
									width="45%"
									variant="subtitle2"
									color={grey[600]}
									display="flex"
									alignItems="center"
									justifyContent="center">
									Opened
								</Typography>
								<Typography
									width="31%"
									variant="subtitle2"
									color={grey[600]}
									display="flex"
									alignItems="center"
									justifyContent="center">
									Closed
								</Typography>
								<Typography
									width="31%"
									variant="subtitle2"
									color={grey[600]}
									display="flex"
									alignItems="center"
									justifyContent="center">
									Total
								</Typography>
							</Box>
							{tableData
								.filter((data) => data.year === "TOTAL")
								.map((row) => {
									let open = row[items.open];
									let closed = row[items.closed];
									let total = closed + open;
									return (
										<Box p="5px" key={row.key} style={{ display: "flex", alignItems: "center" }}>
											<CustomeTooltip
												tooltipData={tableData}
												style={{
													width: "45%",
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
												}}>
												<Typography
													width="100%"
													display="flex"
													alignItems="center"
													justifyContent="center"
													fontWeight="bold"
													color="red">
													<AmountConverter number={open} />
													<div>{`, (${((open / total) * 100).toFixed(2)}%)`}</div>
												</Typography>
											</CustomeTooltip>
											<Typography
												width="31%"
												display="flex"
												alignItems="center"
												justifyContent="center"
												fontWeight="bold"
												color="green">
												<AmountConverter number={closed} />
											</Typography>
											<Typography
												display="flex"
												alignItems="center"
												justifyContent="center"
												fontWeight="bold"
												width="31%">
												<AmountConverter number={total} />
											</Typography>
										</Box>
									);
								})}
						</Paper>
					))}
			</Box>
			{receivableAging.length > 0 && (
				<Box width="85%" padding="10px 0 20px 0">
					<Typography variant="h5" width="100%" pb={1}>
						Receivable Aging
					</Typography>
					<DataGrid
						// key={selectedData}
						getRowId={(row) => row.period}
						rows={receivableAging}
						columns={receivableAgingColumn}
						sx={{
							minHeight: 100,
							"& .MuiDataGrid-row": {
								cursor: "pointer",
							},
							// "& .MuiDataGrid-cell": {
							// 	padding: "10px",
							// },
						}}
						showColumnVerticalBorder
						onRowClick={(params) => {
							// handleGridRowClick(params.row);
						}}
						getRowClassName={(params) => (params.row.month === "" ? "dataGridColorTotal" : "dataGridColor")}
						autoHeight
						disableRowSelectionOnClick
						hideFooter
					/>
				</Box>
			)}
			<Box display="flex" rowGap={5} mb={2} columnGap="3%" width="100%" flexWrap="wrap">
				{data.length > 0 && (
					<Typography variant="h5" width="100%">
						Graphs
					</Typography>
				)}
				{data.length > 0 && (
					<Paper elevation={3}>
						<Stack width={450} display="flex" alignItems="center">
							<Typography variant="h6" display="contents" width="100%" pt={2} pb={2}>
								Sales Vs Return
							</Typography>
							<BarChart
								xAxis={[{ scaleType: "band", data: salesReturn }]}
								series={[
									{
										data: sales,
										valueFormatter: (v, { dataIndex }) => {
											const total = v + returned[dataIndex];
											return `${v}, (${((v / total) * 100).toFixed(2)}%)`;
										},
										label: "Sales",
									},
									{
										data: returned,
										valueFormatter: (v, { dataIndex }) => {
											const total = v + sales[dataIndex];
											return `${v}, (${((v / total) * 100).toFixed(2)}%)`;
										},
										label: "Return",
									},
								]}
								width={450}
								height={250}
								barLabel={(item, context) => {
									const total = sales[item.dataIndex] + returned[item.dataIndex];
									if (context.bar.width < 40) {
										return null;
									}
									return `${((item.value / total) * 100).toFixed(2)}%`;
								}}
								slotProps={{
									legend: {
										itemMarkWidth: 13,
										itemMarkHeight: 13,
										markGap: 5,
										itemGap: 10,
									},
								}}
							/>
						</Stack>
					</Paper>
				)}
				{allData.length > 0 && (
					<Paper elevation={3}>
						<Stack width={450} display="flex" alignItems="center">
							<Typography variant="h6" display="contents" width="100%" pt={2} pb={2}>
								Forward Sales
							</Typography>
							<PieChart
								margin={{ top: 20, bottom: 20, left: 0, right: 170 }}
								series={[
									{
										data: forwardSales.map((d) => ({ label: d.label, id: d.id, value: d.value })),
										valueFormatter: (v, { dataIndex }) => {
											const { total } = forwardSales[dataIndex];
											return `${v.value}, (${((v.value / total) * 100).toFixed(2)}%)`;
										},
									},
								]}
								width={400}
								height={300}
								slotProps={{
									legend: {
										labelStyle: {
											fontSize: 14,
										},
										direction: "column",
										position: { vertical: "middle", horizontal: "right" },
										padding: 0,
										itemMarkWidth: 13,
										itemMarkHeight: 13,
										markGap: 5,
										itemGap: 10,
									},
								}}
							/>
						</Stack>
					</Paper>
				)}
				{allData.length > 0 && (
					<Paper elevation={3}>
						<Stack width={400} display="flex" alignItems="center">
							<Typography variant="h6" display="contents" width="100%" pt={2} pb={2}>
								Status
							</Typography>
							<PieChart
								margin={{ top: 10, bottom: 10, left: 100 }}
								series={[
									{
										data: openClosed.map((d) => ({ label: d.label, id: d.id, value: d.value })),
										valueFormatter: (v, { dataIndex }) => {
											const { total } = openClosed[dataIndex];
											return `${v.value}, (${((v.value / total) * 100).toFixed(2)}%)`;
										},
									},
								]}
								width={400}
								height={250}
								slotProps={{
									legend: {
										// direction: "row",
										// position: { vertical: "top", horizontal: "right" },
										// padding: 0,
										itemMarkWidth: 13,
										itemMarkHeight: 13,
										markGap: 5,
										itemGap: 10,
									},
								}}
							/>
						</Stack>
					</Paper>
				)}
				{allData.length > 0 && (
					<Paper elevation={3} sx={{ display: "flex" }}>
						<Stack width={250} display="flex" alignItems="center">
							<Typography variant="h6" display="contents" width="100%" pt={2} pb={2}>
								Artical Level
							</Typography>
							<PieChart
								margin={{ top: 10, bottom: 10, left: 100 }}
								series={[
									{
										data: artical.map((d) => ({ label: d.label, id: d.id, value: d.value })),
										valueFormatter: (v, { dataIndex }) => {
											const { total } = artical[dataIndex];
											return `${v.value}, (${((v.value / total) * 100).toFixed(2)}%)`;
										},
									},
								]}
								width={300}
								height={250}
								slotProps={{
									legend: {
										// direction: "row",
										position: { vertical: "top", horizontal: "middle" },
										// padding: 0,
										itemMarkWidth: 13,
										itemMarkHeight: 13,
										markGap: 5,
										itemGap: 10,
									},
								}}
							/>
						</Stack>
						<Stack width={250} display="flex" alignItems="center">
							<Typography variant="h6" display="contents" width="100%" pt={2} pb={2}>
								Zonal
							</Typography>
							<PieChart
								margin={{ top: 10, left: 100 }}
								series={[
									{
										data: Zonal.map((d) => ({ label: d.label, id: d.id, value: d.value })),
										valueFormatter: (v, { dataIndex }) => {
											const { total } = Zonal[dataIndex];
											return `${v.value}, (${((v.value / total) * 100).toFixed(2)}%)`;
										},
									},
								]}
								width={290}
								height={250}
								slotProps={{
									legend: {
										// direction: "row",
										position: { vertical: "top", horizontal: "middle" },
										// padding: 0,
										itemMarkWidth: 13,
										itemMarkHeight: 5,
										markGap: 5,
										itemGap: 5,
									},
								}}
							/>
						</Stack>
					</Paper>
				)}
				{nonOrderData.length > 0 && (
					<Paper elevation={3}>
						<Stack width={450} display="flex" alignItems="center">
							<Typography variant="h6" display="contents" width="100%" pt={2} pb={2}>
								Non Order Settlement
							</Typography>
							<BarChart
								xAxis={[{ scaleType: "band", data: creditDebit }]}
								yAxis={[{ label: "Amount (L)" }]}
								series={[
									{ data: credit, label: "Credit" },
									{ data: debit, label: "Debit" },
								]}
								width={450}
								height={250}
								// barLabel="value"
								slotProps={{
									legend: {
										itemMarkWidth: 13,
										itemMarkHeight: 13,
										markGap: 5,
										itemGap: 10,
									},
								}}
							/>
						</Stack>
					</Paper>
				)}
				{allData.length > 0 && (
					<Paper elevation={3}>
						<Stack width={450} display="flex" alignItems="center">
							<Typography variant="h6" display="contents" width="100%" pt={2} pb={2}>
								Reverse Settled
							</Typography>
							<PieChart
								margin={{ top: 50, bottom: 10, left: 0, right: 10 }}
								series={[
									{
										data: reversed.map((d) => ({ label: d.label, id: d.id, value: d.value })),
										valueFormatter: (v, { dataIndex }) => {
											const { total } = reversed[dataIndex];
											return `${v.value}, (${((v.value / total) * 100).toFixed(2)}%)`;
										},
									},
								]}
								width={300}
								height={300}
								slotProps={{
									legend: {
										labelStyle: {
											fontSize: 14,
										},
										direction: "row",
										position: { vertical: "top", horizontal: "right" },
										padding: 0,
										itemMarkWidth: 13,
										itemMarkHeight: 13,
										markGap: 5,
										itemGap: 10,
									},
								}}
							/>
						</Stack>
					</Paper>
				)}
				{data.length > 0 && (
					<Paper elevation={3}>
						<Stack width={450} display="flex" alignItems="center">
							<Typography variant="h6" display="contents" width="100%" pt={2} pb={2}>
								Logistics
							</Typography>
							<BarChart
								xAxis={[{ scaleType: "band", data: salesReturn }]}
								yAxis={[{ label: "Amount (L)" }]}
								series={[{ data: logistics, label: "Logistics" }]}
								width={450}
								height={250}
								// barLabel="value"
								slotProps={{
									legend: {
										itemMarkWidth: 13,
										itemMarkHeight: 13,
										markGap: 5,
										itemGap: 10,
									},
								}}
							/>
						</Stack>
					</Paper>
				)}
				{allData.length > 0 && (
					<Paper elevation={3}>
						<Stack width={450} display="flex" alignItems="center">
							<Typography variant="h6" display="contents" width="100%" pt={2} pb={2}>
								Ginesys Brand Sale
							</Typography>
							<PieChart
								margin={{ top: 10, bottom: 10, left: 0, right: 10 }}
								series={[
									{
										data: brand.map((d) => ({ label: d.label, id: d.id, value: d.value })),
										valueFormatter: (v, { dataIndex }) => {
											const { total } = brand[dataIndex];
											return `${v.value}, (${((v.value / total) * 100).toFixed(2)}%)`;
										},
									},
								]}
								width={200}
								height={300}
								slotProps={{
									legend: {
										direction: "row",
										position: { vertical: "top", horizontal: "middle" },
										padding: 0,
										itemMarkWidth: 13,
										itemMarkHeight: 13,
										markGap: 5,
										itemGap: 10,
									},
								}}
							/>
						</Stack>
					</Paper>
				)}
			</Box>
			{data.length > 0 && (
				<Stack width="100%" sx={{ mb: 3 }}>
					<DataGrid
						// key={selectedData}
						getRowId={(row) => row.month_year}
						rows={tableData}
						columns={summaryTable}
						sx={{
							minHeight: 100,
							"& .MuiDataGrid-row": {
								cursor: "pointer",
							},
							"& .MuiDataGrid-cell": {
								padding: "10px",
							},
						}}
						showColumnVerticalBorder
						onRowClick={(params) => {
							// handleGridRowClick(params.row);
						}}
						getRowClassName={(params) => (params.row.month === "" ? "dataGridColorTotal" : "dataGridColor")}
						autoHeight
						disableRowSelectionOnClick
						hideFooter
					/>
				</Stack>
			)}
			<Box display="flex" rowGap={5} mb={2} columnGap="8%" width="100%" flexWrap="wrap">
				{data.map((items) => (
					<Paper sx={{ minWidth: "40%", padding: 1 }} elevation={3}>
						<Box p={1} sx={{ display: "flex", justifyContent: "space-between" }}>
							<Typography width="50%" variant="subtitle1" fontWeight="bold" color={grey[700]} textAlign="left">
								{getMonthName(items.year, items.month)}-{items.year}
							</Typography>
							<Typography width="23%" variant="subtitle2" color={grey[600]} textAlign="center">
								Qty
							</Typography>
							<Typography width="23%" variant="subtitle2" color={grey[600]} textAlign="center">
								Amount
							</Typography>
						</Box>
						{summaryData.map((row) => (
							<Box p="5px" key={row.key} style={{ display: "flex", justifyContent: "space-between" }}>
								<Typography width="50%" variant="subtitle2">
									{row.name}
								</Typography>
								<Typography width="23%" textAlign="center">
									{items[row.qty] || 0}
								</Typography>
								<Typography minWidth="23%" textAlign="center">
									<AmountConverter number={items[row.realised_value]} />
								</Typography>
								{/* <Divider /> */}
							</Box>
						))}
					</Paper>
				))}
			</Box>
		</div>
	);
};

export default MyntraOmniDashboard;
