export const myntraRecoUrlParamName = "reco";

export const myntra_reco_drawer = {
	dashboard: {
		name: "Dashboard",
		key: "dashboard",
		display: true,
	},
	upload_files: {
		name: "Upload Files",
		key: "upload_files",
		display: true,
	},
	summary: {
		name: "Summary",
		key: "summary",
		display: true,
	},
	documents: {
		name: "Documents",
		key: "documents",
		display: true,
	},
};

export const final_col = [
	"Order_No",
	"Date",
	"Type",
	"Barcode",
	"Net_Amount",
	"SEASON",
	"forward_order_release_id",
	"forward_order_line_id",
	"product_tax_category",
	"article_level",
	"customer_delivery_pin_code",
	"shipping_pin_code",
	// "gender",
	"customer_paid_amount",
	"igst_amount",
	"cgst_amount",
	"sgst_amount",
	"tcs_amount",
	"calculated_tcs_amt",
	"tcs_amt_diff",
	"tds_amount",
	"calculated_tds_amt",
	"tds_amt_diff",
	"commission_percentage",
	"calculated_commission_percentage",
	"commission_percentage_diff",
	"platform_fees",
	"calculated_platform_fee",
	"platform_fees_diff",
	"forward_shipping_fee",
	"calculated_shepping_fee",
	"forward_shipping_fee_diff",
	"fixed_fee",
	"calculated_fixed_fee",
	"fixed_fee_diff",
	"payment_gateway_fee",
	"calculated_paymentgatway_fee",
	"payment_gateway_fee_diff",
	"total_tax_on_logistics",
	"calculated_total_tax_on_logistics",
	"total_tax_on_logistics_diff",
	"forward_total_logistics_deduction",
	"calculated_total_logistic_deduction",
	"forward_logistics_deduction_diff",
	"shipment_zone_classification",
	"calculated_zonal",
	"shipment_zone_classification_diff",
	"total_expected_settlement",
	"total_actual_settlement",
	"calculated_total_expected_settlement_amt",
	"total_settled_amt_in_month",
	"total_settled_amt_diff",
	"reverse_order_line_id",
	"reverse_shipping_fee",
	"calculated_reverse_shepping_fee",
	"reverse_fixed_fee",
	"reverse_shipping_fee_diff",
	"reverse_total_tax_on_logistics",
	"calculated_reverse_tax_on_logistics",
	"reverse_total_tax_on_logistics_diff",
	"reverse_shipment_zone_classification",
	"calculated_reverse_zonal",
	"reverse_shipment_zone_classification_diff",
	"total_settlement",
	"reverse_total_actual_settlement",
	"total_forword_settled_amt",
	"total_logistic_in_forword_settle",
	"reverse_total_logistics_deduction",
	"calculated_total_reverse_logistics",
	"reverse_total_logistics_deduction_diff",
	"total_reverse_settled_amt_in_month",
	"total_calculated_reverse_amt",
	"total_reverse_settled_amt_diff",
	"retruned_product_Net_Amount",
	"Ticket id",
	"Ticket_created",
	"Lot_Name",
	"Order_id",
	"Issue_category",
	"Model",
	"Final Compensation Value",
	"UTR no",
	"UTR date",
	"UTR amount",
	// "status",
];
export const required_col = [
	"Order_No",
	"Date",
	"Net_Amount",
	"calculated_total_expected_settlement_amt",
	"total_settled_amt_in_month",
	"total_settled_amt_diff",
	"total_reverse_settled_amt_in_month",
	"total_calculated_reverse_amt",
	"total_reverse_settled_amt_diff",
	"retruned_product_Net_Amount",
	"Lot_Name",
	"Order_id",
	"Issue_category",
	// "Model",
	"Final Compensation Value",
	// "status",
];

export const summary_col = [
	"Brand",
	"Date",
	"Order_no",
	"Debit",
	"Credit",
	"Net",
	"Delivery_date",
	"Return_date",
	"Myntra_commission",
	"Myntra_TCS",
	"Myntra_TDS",
	"Myntra_shipping_fee",
	"Myntra_fixed_fee",
	"Myntra_payment_gateway_fee",
	"Myntra_logistic",
	"Myntra_Total_deduction",
	"Myntra_Balance_receivable",
	"Myntra_Settled_amount",
	"Myntra_Balance",
	"Spf_amt_recived",
	"Final_Balance",
	"Spf_short",
	"Spf_status",
	// 'Status',
	"Calculated_commission",
	"Calculated_TDS",
	"Calcutaled_TCS",
	"Calculated_shipping_fee",
	"Calculated_fixed_fee",
	"Calculated_payment_gateway_fee",
	"Calculated_logistic",
	"Calculated_Total_deduction",
	"Calculated_Balance_receivable",
	"Calculated_Balance",
];

export const myntra_data = [
	{
		Order_No: "27290fce-2347-44c1-8fe1-509d25fffa05",
		Date: "2023-05-21",
		Type: "Sale",
		Barcode: "8907279063663",
		Net_Amount: 4999.0,
		SEASON: ".",
		product_tax_category: null,
		article_level: null,
		customer_delivery_pin_code: null,
		shipping_pin_code: null,
		gender: null,
		customer_paid_amount: null,
		igst_amount: null,
		cgst_amount: null,
		sgst_amount: null,
		tcs_amount: null,
		claculated_tcs_amt: null,
		tds_amount: null,
		claculated_tds_amt: null,
		commission_percentage: null,
		calculated_commission_percentage: null,
		platform_fees: null,
		calculated_platform_fee: null,
		forward_shipping_fee: null,
		calculated_shepping_fee: null,
		fixed_fee: null,
		calculated_fixed_fee: null,
		payment_gateway_fee: null,
		calculated_paymentgatway_fee: null,
		total_tax_on_logistics: null,
		total_logistic: null,
		calculated_total_logistic_deduction: null,
		shipment_zone_classification: null,
		calculated_zonal: null,
		total_expected_settlement: null,
		total_actual_settlement: null,
		total_expected_settlement_amt: null,
		total_settled_amt_in_month: null,
		reverse_shipping_fee: null,
		calculated_reverse_shepping_fee: 0,
		reverse_fixed_fee: null,
		reverse_total_tax_on_logistics: null,
		reverse_shipment_zone_classification: null,
		calculated_reverse_zonal: null,
		total_settlement: null,
		reverse_total_actual_settlement: null,
		total_forword_settled_amt: null,
		total_logistic_in_forword_settle: null,
		total_logistics_deduction: null,
		total_reverse_logistics: 0.0,
		total_reverse_settled_amt_in_month: 0.0,
		total_calculated_reverse_amt: null,
		return_Net_Amount: null,
		"Ticket id": null,
		Ticket_created: null,
		"Payment method": null,
		Lot_Name: null,
		Order_id: null,
		Issue_category: null,
		Seller_ID: null,
		Model: null,
		"Final Compensation Value": null,
		"UTR no": null,
		"UTR date": null,
		"UTR amount": null,
	},
	{
		Order_No: "bb38d0d7-5bc5-4350-bd54-64bbbd2e0ddd",
		Date: "2023-05-07",
		Type: "Sale",
		Barcode: "8907279063779",
		Net_Amount: 1999.0,
		SEASON: ".",
		product_tax_category: null,
		article_level: null,
		customer_delivery_pin_code: null,
		shipping_pin_code: null,
		gender: null,
		customer_paid_amount: null,
		igst_amount: null,
		cgst_amount: null,
		sgst_amount: null,
		tcs_amount: null,
		claculated_tcs_amt: null,
		tds_amount: null,
		claculated_tds_amt: null,
		commission_percentage: null,
		calculated_commission_percentage: null,
		platform_fees: null,
		calculated_platform_fee: null,
		forward_shipping_fee: null,
		calculated_shepping_fee: null,
		fixed_fee: null,
		calculated_fixed_fee: null,
		payment_gateway_fee: null,
		calculated_paymentgatway_fee: null,
		total_tax_on_logistics: null,
		total_logistic: null,
		calculated_total_logistic_deduction: null,
		shipment_zone_classification: null,
		calculated_zonal: null,
		total_expected_settlement: null,
		total_actual_settlement: null,
		total_expected_settlement_amt: null,
		total_settled_amt_in_month: null,
		reverse_shipping_fee: null,
		calculated_reverse_shepping_fee: 0,
		reverse_fixed_fee: null,
		reverse_total_tax_on_logistics: null,
		reverse_shipment_zone_classification: null,
		calculated_reverse_zonal: null,
		total_settlement: null,
		reverse_total_actual_settlement: null,
		total_forword_settled_amt: null,
		total_logistic_in_forword_settle: null,
		total_logistics_deduction: null,
		total_reverse_logistics: 0.0,
		total_reverse_settled_amt_in_month: 0.0,
		total_calculated_reverse_amt: null,
		return_Net_Amount: -1999.0,
		"Ticket id": null,
		Ticket_created: null,
		"Payment method": null,
		Lot_Name: null,
		Order_id: null,
		Issue_category: null,
		Seller_ID: null,
		Model: null,
		"Final Compensation Value": null,
		"UTR no": null,
		"UTR date": null,
		"UTR amount": null,
	},
	{
		Order_No: "bb38d0d7-5bc5-4350-bd54-64bbbd2e0ddc",
		Date: "2023-05-07",
		Type: "Sale",
		Barcode: "8907279063779",
		Net_Amount: 1999.0,
		SEASON: ".",
		product_tax_category: null,
		article_level: null,
		customer_delivery_pin_code: null,
		shipping_pin_code: null,
		gender: null,
		customer_paid_amount: null,
		igst_amount: null,
		cgst_amount: null,
		sgst_amount: null,
		tcs_amount: null,
		claculated_tcs_amt: null,
		tds_amount: null,
		claculated_tds_amt: null,
		commission_percentage: null,
		calculated_commission_percentage: null,
		platform_fees: null,
		calculated_platform_fee: null,
		forward_shipping_fee: null,
		calculated_shepping_fee: null,
		fixed_fee: null,
		calculated_fixed_fee: null,
		payment_gateway_fee: null,
		calculated_paymentgatway_fee: null,
		total_tax_on_logistics: null,
		total_logistic: null,
		calculated_total_logistic_deduction: null,
		shipment_zone_classification: null,
		calculated_zonal: null,
		total_expected_settlement: null,
		total_actual_settlement: null,
		total_expected_settlement_amt: null,
		total_settled_amt_in_month: null,
		reverse_shipping_fee: null,
		calculated_reverse_shepping_fee: 0,
		reverse_fixed_fee: null,
		reverse_total_tax_on_logistics: null,
		reverse_shipment_zone_classification: null,
		calculated_reverse_zonal: null,
		total_settlement: null,
		reverse_total_actual_settlement: null,
		total_forword_settled_amt: null,
		total_logistic_in_forword_settle: null,
		total_logistics_deduction: null,
		total_reverse_logistics: 0.0,
		total_reverse_settled_amt_in_month: 0.0,
		total_calculated_reverse_amt: null,
		return_Net_Amount: -1999.0,
		"Ticket id": null,
		Ticket_created: null,
		"Payment method": null,
		Lot_Name: null,
		Order_id: null,
		Issue_category: null,
		Seller_ID: null,
		Model: null,
		"Final Compensation Value": null,
		"UTR no": null,
		"UTR date": null,
		"UTR amount": null,
	},
];
