import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { GlobalState } from "context/GlobalContext";

function ForgotPasswordModal({ open, setOpen }) {
	const { snackBar, setSnackBar, setSnackBarOpen, setLoginUser, setLoadingOpen } = GlobalState();
	const [email, setEmail] = useState("");

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const sendResetMail = async () => {
		await axios
			.post("/api/auth/forgot_password", { email })
			.then((res) => {
				console.log(res);
				setLoadingOpen(true);
				if (res.status === 200) {
					setSnackBarOpen(true);
					setSnackBar({ ...snackBar, message: "Reset mail sent successfully", severity: "success" });
					setLoadingOpen(false);
					return;
				} else if (res.status === 404) {
					setSnackBarOpen(true);
					setSnackBar({ ...snackBar, message: "User not found with provided email", severity: "error" });
					setLoadingOpen(false);
					return;
				}
			})
			.catch((err) => {
				console.log(err);
				setSnackBarOpen(true);
				setSnackBar({ ...snackBar, message: err.response.data.error ?? "Something went wrong", severity: "error" });
				setLoadingOpen(false);
				return;
			});
	};

	return (
		<React.Fragment>
			<Dialog
				open={open}
				onClose={handleClose}
				fullWidth
				maxWidth="xs"
				PaperProps={{
					component: "form",
					sx: { p: 2, boxSizing: "border-box" },
					onSubmit: (event) => {
						event.preventDefault();
						sendResetMail();
						handleClose();
					},
				}}>
				<DialogTitle>Forgot Password ?</DialogTitle>
				<DialogContent>
					<DialogContentText>Please enter your email address.</DialogContentText>
					<TextField
						autoFocus
						required
						margin="dense"
						id="name"
						name="email"
						label="Email Address"
						type="email"
						fullWidth
						variant="outlined"
						size="small"
						onChange={(e) => setEmail(e.target.value)}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button type="submit">Send Link</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}

export default ForgotPasswordModal;
