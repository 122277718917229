import React, { useState } from "react";
import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import { KeyboardBackspaceRounded } from "@mui/icons-material";
import { useNavigate, useOutletContext } from "react-router-dom";
import { myntra_reco_drawer } from "data/myntraRecoData";
import MyntraSummary from "./myntraSummary";
import UploadMyntraFiles from "./uploadMyntraFiles";
import MyntraRecoProvider from "context/MyntraReco";
import MyntraDashboard from "./myntraDashboard";
import RecoDocuments from "./recoDocuments";
import MyntraOmniDashboard from "./MyntraOmni/myntraOmniDashboard";
import MyntraOmniFileUpload from "./MyntraOmni/uploadMynbtraOmniFiles";
import MyntraOmniSummary from "./MyntraOmni/myntraOmniSummary";
import OmniRecoDocuments from "./MyntraOmni/myntraOmniDocuments";

export default function Myntra_reco() {
	const [tab, setTab] = useOutletContext();
	const [horizontalTab, setHorizontalTab] = useState(0);
	const navigate = useNavigate();
	const tabComponents = [
		{
			...myntra_reco_drawer.dashboard,
			component: horizontalTab === 0 ? <MyntraDashboard /> : <MyntraOmniDashboard />,
		},
		{
			...myntra_reco_drawer.upload_files,
			component: horizontalTab === 0 ? <UploadMyntraFiles /> : <MyntraOmniFileUpload />,
		},
		{
			...myntra_reco_drawer.summary,
			component: horizontalTab === 0 ? <MyntraSummary /> : <MyntraOmniSummary />,
		},
		{
			...myntra_reco_drawer.documents,
			component: horizontalTab === 0 ? <RecoDocuments /> : <OmniRecoDocuments />,
		},
	];
	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
		};
	}

	const handleTabChange = (event, newValue) => {
		setHorizontalTab(newValue);
	};
	const tabStyle = { textTransform: "capitalize", fontSize: "16px", width: "170px" };
	return (
		<div>
			<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
				<div>
					<Typography variant="h5" margin={"20px"} fontSize={28} textTransform="uppercase">
						MArketplace Reco
					</Typography>
				</div>
				<Button startIcon={<KeyboardBackspaceRounded />} variant="outlined" onClick={() => navigate("/explore")} sx={{ m: 2 }}>
					Back
				</Button>
			</div>
			<Tabs value={horizontalTab} onChange={handleTabChange} sx={{ borderBottom: 1, borderColor: "divider" }}>
				<Tab sx={tabStyle} label="Myntra COD" {...a11yProps(0)} />
				<Tab sx={tabStyle} label="Myntra OMNI" {...a11yProps(1)} />
			</Tabs>
			{
				<MyntraRecoProvider>
					<div>
						{tabComponents &&
							tabComponents.map((row) => (
								<Box width="95%" margin="auto" key={row.key} display={row.key === tab ? "block" : "none"}>
									{row.component}
								</Box>
							))}
					</div>
				</MyntraRecoProvider>
			}
		</div>
	);
}
