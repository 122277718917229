import { Box, Button, Typography } from "@mui/material";
import moment from "moment";
import {
	CheckCircleOutlineRounded,
	DangerousRounded,
	DescriptionRounded,
	DocumentScanner,
	EditNote,
	Publish,
} from "@mui/icons-material";
import { blue, grey, lightGreen, red, yellow } from "@mui/material/colors";

export const constraintsOptions = {
	UNIQUE: { name: "Unique", key: "UNIQUE", editable: false },
	NOTNULL: { name: "REQUIRED", key: "NOTNULL", editable: true },
	INPUT: { name: "INPUT", key: "INPUT", editable: true },
	DISPLAY: { name: "Display", key: "DISPLAY", editable: true },
};
export const objType = "obj";
export const arrType = "arr";

export const inputTypes = {
	SELECT: { name: "Select", key: "SELECT" },
	MULTIPLE_SELECT: { name: "Multiple Select", key: "MULTIPLE_SELECT" },
	ENTER: { name: "Enter", key: "ENTER" },
	AUTO_GENERATE: { name: "Autogenerate", key: "AUTO_GENERATE" },
};
export const datatypes = {
	STRING: { name: "String", key: "STRING", htmlVar: "string", sqlVar: "varchar(255)" },
	LONGTEXT: { name: "Long Text", key: "LONGTEXT", htmlVar: "string", sqlVar: "LONGTEXT" },
	FILE: { name: "FILE", key: "FILE", htmlVar: "file", sqlVar: "varchar(255)" },
	NUMBER: { name: "Number", key: "NUMBER", htmlVar: "number", sqlVar: "integer" },
	DECIMAL: { name: "Decimal", key: "DECIMAL", htmlVar: "number", sqlVar: "float" },
	DATE: { name: "Date", key: "DATE", htmlVar: "date", sqlVar: "DATETIME" },
	JSON: { name: "Json", key: "JSON", htmlVar: "file", sqlVar: "JSON" },
};

export const colStyle = {
	headerAlign: "center",
	align: "center",
	flex: 1,
	minWidth: 120,
};

const tableMetadataDatagridRenderCells = {
	url: {
		field: "url",
		headerName: "URL",
		minWidth: 120,
		...colStyle,
		flex: 1,
		renderCell: ({ row, field }) => {
			return (
				<Typography component={row?.file ? "data" : "a"} href={row?.[field]} target="_blank">
					{row?.[field]}
				</Typography>
			);
		},
	},
};
export const converAttrToDatagridCol = (attr) => {
	if (tableMetadataDatagridRenderCells?.[attr.key]) return tableMetadataDatagridRenderCells[attr.key];
	else
		return {
			field: attr?.key,
			headerName: attr?.name,
			minWidth: 120,
			...colStyle,
			flex: 1,
			valueGetter: (value, row) => {
				if (attr?.input_type === inputTypes.SELECT.key) {
					// return attr?.[selectOptionsKeyName]?.list?.find((option) => option.key === value)
					// 	?.name;
					return value;
				} else if (attr?.input_type === inputTypes.MULTIPLE_SELECT.key) {
					return value?.length
						? value
								.map((val) => {
									// return 	attr?.[selectOptionsKeyName]?.list?.find(
									// 		(option) => option.key === val
									// 	)?.name
									return val;
								})
								.join(", ")
						: "";
				} else if (attr?.data_type === datatypes.DATE.key) {
					return value ? moment(value).format("DD/MM/YYYY") : "";
				} else {
					return value;
				}
			},
		};
};

export const statusOptions = {
	CREATED: { name: "Created", color: blue[300], key: "CREATED", icon: <EditNote /> },
	NEW: { name: "New", color: blue[300], key: "NEW", icon: <DocumentScanner /> },
	DRAFTED: { name: "Drafted", color: grey[300], key: "DRAFTED", icon: <DescriptionRounded /> },
	APPROVED: { name: "Approved", color: lightGreen["A700"], key: "APPROVED", icon: <CheckCircleOutlineRounded /> },
	PUBLISHED: { name: "Published", color: lightGreen["A700"], key: "PUBLISHED", icon: <CheckCircleOutlineRounded /> },

	REJECTED: { name: "Rejected", color: red[600], key: "REJECTED", icon: <DangerousRounded /> },
};

export const publishColumns = {
	status: {
		name: "Status",
		key: "status",
		input_type: inputTypes.AUTO_GENERATE.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 0,
		meta_data: true,
	},
	published_by: {
		name: "Published By",
		key: "published_by",
		input_type: inputTypes.AUTO_GENERATE.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 0,
		meta_data: true,
	},
	published_date: {
		name: "Published Date",
		key: "published_date",
		input_type: inputTypes.AUTO_GENERATE.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.DATE.key,
		sort_no: 0,
		meta_data: true,
	},
};

export const publishTableColumn = (publishFun = () => {}, disableBtn = false) => {
	return {
		headerName: publishColumns.status.name,
		field: publishColumns.status.key,
		minWidth: 130,
		headerAlign: "center",
		align: "center",
		flex: 1,

		renderCell: ({ row }) => {
			if (row?.[publishColumns.status.key] === (statusOptions.PUBLISHED.key || statusOptions.REJECTED.key))
				return (
					<Box display="flex" width="100%" height="100%" alignItems="center" justifyContent="center">
						<Box
							display="flex"
							alignItems="center"
							height={"22px"}
							// columnGap={1}
							sx={{
								backgroundColor: statusOptions[row.status]?.color || yellow[400],
								color: "white",
								padding: "6px",
								borderRadius: "5px",
							}}>
							{statusOptions[row.status]?.icon}{" "}
							<Typography pl="3px"> {statusOptions[row.status]?.name ?? "----"}</Typography>
						</Box>
					</Box>
				);
			else if (disableBtn)
				return (
					<Box display="flex" width="100%" height="100%" alignItems="center" justifyContent="center">
						<Box
							display="flex"
							alignItems="center"
							height={"22px"}
							// columnGap={1}
							sx={{
								backgroundColor: statusOptions[row.status]?.color || yellow[400],
								color: "white",
								padding: "6px",
								borderRadius: "5px",
							}}>
							{statusOptions[row.status]?.icon}{" "}
							<Typography pl="3px"> {statusOptions[row.status]?.name ?? "----"}</Typography>
						</Box>
					</Box>
				);
			else
				return (
					<Button onClick={(e) => publishFun(e, row)} variant="contained" size="small" startIcon={<Publish />}>
						Publish
					</Button>
				);
		},
	};
};
