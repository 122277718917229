import React, { useState, useEffect } from "react";
import {
	Box,
	Card,
	CardContent,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Grid,
	Stack,
} from "@mui/material";
import { GlobalState } from "context/GlobalContext";
import axios from "axios";
import { InLakhsDisplay } from "components/ReusableComponents/InLakhsDisplay";

function Summary() {
	const [summaryData, setSummaryData] = useState({});
	const [topStores, setTopStores] = useState([]);
	const [open, setOpen] = useState(false);
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();

	const handleClick = (value) => {
		setOpen(true);
		navigator.clipboard.writeText(value);
		setSnackBarOpen(true);
		setSnackBar({
			...snackBar,
			message: "Copied to clipboard",
			severity: "success",
			anchorOrigin: { horizontal: "center", vertical: "top" },
		});
	};

	useEffect(() => {
		const fetch = async () => {
			try {
				const [summaryResponse, topFiveStores] = await Promise.all([
					axios.get("/api/pos_reco/get-pos-summary"),
					axios.get("/api/pos_reco/get-top-stores"),
				]);
				console.log(topFiveStores);
				setSummaryData(summaryResponse.data[0]);
				setTopStores(topFiveStores.data);
			} catch (error) {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "Eror fetching summary",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			}
		};
		fetch();
	}, []);

	const cardData = Object.entries(summaryData)
		.filter(([key, value]) => key !== "MOP" && value !== null)
		.map(([key, value]) => ({
			label: key,
			value: value,
		}));

	return (
		<Box sx={{ flexGrow: 1 }}>
			<Typography variant="h4" sx={{ mb: 2 }}>
				Sale Summary
			</Typography>
			<Grid container spacing={3} sx={{ mb: 4 }}>
				{cardData.map((card, index) => (
					<Grid item xs={12} sm={3} key={index}>
						<Card>
							<CardContent>
								<Typography variant="h6" component="div">
									{card.label}
								</Typography>
								<Typography variant="h4" component="div" onClick={() => handleClick(card.value)}>
									<InLakhsDisplay number={card.value} />
								</Typography>
							</CardContent>
						</Card>
					</Grid>
				))}
			</Grid>

			{/* Table */}
			<Stack direction="row" justifyContent={"space-between"}>
				<Stack width={"47%"}>
					<Typography variant="h4" sx={{ mb: 2 }}>
						Top 5 Stores By Sales
					</Typography>
					<TableContainer component={Paper}>
						<Table aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>Store</TableCell>
									<TableCell>Sale</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{topStores.map((row) => (
									<TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
										<TableCell component="th" scope="row">
											{row.NAME}
										</TableCell>
										<TableCell>{row.TotalSales}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Stack>
				<Stack width={"47%"}>
					<Typography variant="h4" sx={{ mb: 2 }}>
						Bottom 5 Stores By Sales
					</Typography>
					<TableContainer component={Paper}>
						<Table aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>Store</TableCell>
									<TableCell>Sale</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{topStores.map((row) => (
									<TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
										<TableCell component="th" scope="row">
											{row.NAME}
										</TableCell>
										<TableCell>{row.TotalSales}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Stack>
			</Stack>
		</Box>
	);
}

export default Summary;
