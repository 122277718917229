import { ClearRounded } from "@mui/icons-material";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	Divider,
	IconButton,
	InputLabel,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import CustomTextField from "components/EboStoreV4/CommonComponents/CustomTextfield/CustomTextfield";
import RenderInputField from "components/EboStoreV4/CommonComponents/RenderInputFiled/RenderInputFiled";
import {
	addOrEditModelAction,
	autoUpdatestoreMainFieldsData,
	billerDetailsTableColumns,
	getColDefaultValue,
	leaseDetails,
	storeBasicDetailsFields,
	storeCamTableColumns,
	storeDepositTableColumns,
	storeMainFields,
	storeRentTableColumns,
	storesLinkedTables,
	storeTypeDetails,
} from "components/EboStoreV4/data";
import { constraintsOptions } from "data/globalData";
import { inputTypes, statusOptions } from "data/pimData";
import { agreementData } from "data/supplierPageData";
import { useState } from "react";

const AddOrEditModal = ({
	modal,
	setModal,
	type,
	selectedObj,
	createDetailsApi,
	selectedStore,
	setSelectedStore,
	handleUpdateDetails,
}) => {
	const defFields = (() => {
		let obj = {};
		storeDepositTableColumns?.forEach((attr) => {
			obj[attr.key] = getColDefaultValue(attr);
		});
		return obj;
	})();
	const [depositDetails, setDepositDetails] = useState(type === addOrEditModelAction.UPDATE ? selectedObj : defFields);

	const handleModalclose = () => {
		setModal(false);
	};
	const handleDepositCallbackFun = (data) => {
		if (type === addOrEditModelAction.UPDATE) {
			let filtredData = selectedStore?.eboStoreDepositDetails.filter((data) => data.id !== depositDetails.id);
			let depositDetail = [...filtredData, depositDetails];
			setSelectedStore({ ...selectedStore, eboStoreDepositDetails: depositDetail });
		} else {
			let depositDetails = [...selectedStore?.eboStoreDepositDetails, data];
			setSelectedStore({ ...selectedStore, eboStoreDepositDetails: depositDetails });
		}
		setDepositDetails(defFields);
		setModal(false);
	};
	const handleFormSubmit = async (e) => {
		e.preventDefault();
		let updatedDeposit = { ...depositDetails, ebo_store_v4_id: selectedStore.store_code };
		if (type === addOrEditModelAction.UPDATE) {
			handleUpdateDetails(storesLinkedTables.store_deposit_details.seqMethod, updatedDeposit, handleDepositCallbackFun);
		} else {
			createDetailsApi(storesLinkedTables.store_deposit_details.seqMethod, updatedDeposit, handleDepositCallbackFun);
		}
	};

	return (
		<Dialog maxWidth="md" fullWidth open={modal} onClose={handleModalclose} component="form" onSubmit={handleFormSubmit}>
			<Box p={2}>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Typography variant="h6">{type === addOrEditModelAction.UPDATE ? "Update" : "Add"} Deposit </Typography>
					<Box>
						<IconButton onClick={handleModalclose}>
							<ClearRounded />
						</IconButton>
					</Box>
				</Box>
				<Stack spacing={1} width="100%">
					{" "}
					<Box display="flex" columnGap={"10px"} rowGap="10px" flexWrap="wrap" width="100%">
						{storeDepositTableColumns?.map((row) => {
							return (
								<RenderInputField
									column={row}
									data={depositDetails}
									setData={setDepositDetails}
									width="24%"
									selectOptObjName={"option_data"}
								/>
							);
						})}
					</Box>
				</Stack>

				<DialogActions sx={{ pt: 2 }}>
					<Button variant="outlined" sx={{ justifySelf: "center" }} onClick={handleModalclose}>
						Cancel
					</Button>
					<Button
						variant="contained"
						sx={{ justifySelf: "center" }}
						disabled={selectedStore.status === statusOptions.PUBLISHED.key}
						type="submit">
						{type === addOrEditModelAction.UPDATE ? "UPDATE" : "CREATE"}
					</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
};

export default AddOrEditModal;
