import { ClearRounded } from "@mui/icons-material";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	Divider,
	IconButton,
	InputLabel,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import AddFiles from "components/EboStoreV4/CommonComponents/AddFiles/AddFiles";
import CustomTextField from "components/EboStoreV4/CommonComponents/CustomTextfield/CustomTextfield";
import RenderInputField from "components/EboStoreV4/CommonComponents/RenderInputFiled/RenderInputFiled";
import {
	addOrEditModelAction,
	autoUpdatestoreLeaseFieldsData,
	autoUpdatestoreMainFieldsData,
	getColDefaultValue,
	leaseAgreementTableColumns,
	leaseDetails,
	storeBasicDetailsFields,
	storeCapexTableColumns,
	storeCapexTableOptions,
	storeMainFields,
	storesLinkedTables,
	storeTypeDetails,
} from "components/EboStoreV4/data";
import { arrType, constraintsOptions } from "data/globalData";
import { inputTypes } from "data/pimData";
import { agreementData } from "data/supplierPageData";
import { useEffect, useState } from "react";

const AddOrEditModal = ({
	modal,
	setModal,
	type,
	defaultData,
	createDetailsApi,
	selectedStore,
	setSelectedStore,
	handleUpdateDetails,
	handleGetStoreFiles,
	handleFileUploadDetails,
}) => {
	const defFields = (() => {
		let obj = {};
		storeCapexTableColumns
			.filter((data) => data.data_type !== "FILE")
			?.forEach((attr) => {
				obj[attr.key] = getColDefaultValue(attr);
			});
		return obj;
	})();
	const [formData, setFormData] = useState(type === addOrEditModelAction.UPDATE ? defaultData : defFields);
	const [agreementFile, setAgreementFile] = useState();

	const [allFiles, setAllFiles] = useState([]);

	const filtTypeOptions = {
		type: arrType,
		list: storeCapexTableOptions,
	};

	const handleCallback = (data) => {
		setAllFiles(data);
	};

	useEffect(() => {
		defaultData !== undefined && handleGetStoreFiles(storesLinkedTables.store_capex_details.key, defaultData?.id, handleCallback);
	}, []);
	const handleFileChange = (e) => {
		const file = e.target.files[0];
		setAgreementFile(file);
	};

	const handleAddFile = (data) => {
		setAllFiles([...allFiles, data]);
	};

	const handleModalclose = () => {
		setModal(false);
	};

	const handleCallBackFun = (data) => {
		let eboStoreCapexDetails = selectedStore.eboStoreCapexDetails;
		eboStoreCapexDetails.shift();
		if (type === addOrEditModelAction.UPDATE) {
			let updatedData = [formData];
			setSelectedStore({ ...selectedStore, eboStoreCapexDetails: updatedData });
		} else {
			let updatedData = [data];
			setSelectedStore({ ...selectedStore, eboStoreCapexDetails: updatedData });
		}
		setFormData(defFields);
		setModal(false);
	};

	const handleFormSubmit = async (e) => {
		e.preventDefault();
		const filtredFiles = allFiles.filter((data) => data?.isNew);
		//this if for file upload
		let updatedData = await handleFileUploadDetails(
			filtredFiles,
			selectedStore.store_code,
			storesLinkedTables.store_capex_details.key
		);
		let updatedBiller = { ...formData, ebo_store_v4_id: selectedStore.store_code, files: updatedData };
		if (type === addOrEditModelAction.UPDATE) {
			await handleUpdateDetails(storesLinkedTables.store_capex_details.seqMethod, updatedBiller, handleCallBackFun);
		} else {
			await createDetailsApi(storesLinkedTables.store_capex_details.seqMethod, updatedBiller, handleCallBackFun);
		}
	};
	console.log("rdtygjikol", formData);
	return (
		<Dialog maxWidth="md" fullWidth open={modal} onClose={handleModalclose} component="form" onSubmit={handleFormSubmit}>
			<Box p={2}>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Typography variant="h6">{type === addOrEditModelAction.UPDATE ? "Update" : "Add"} Capex Details</Typography>
					<Box>
						<IconButton onClick={handleModalclose}>
							<ClearRounded />
						</IconButton>
					</Box>
				</Box>
				<Stack spacing={1} width="100%">
					{" "}
					<Box display="flex" columnGap={"10px"} rowGap="10px" flexWrap="wrap" width="100%">
						{storeCapexTableColumns
							?.filter((data) => data.data_type !== "FILE")
							?.map((row) => {
								let options = {};
								if (row.key === "rent_shedule") {
									options = filtTypeOptions;
								}
								return (
									<RenderInputField
										column={{ ...row, options }}
										data={formData}
										setData={setFormData}
										width="24%"
										selectOptObjName={"option_data"}
									/>
								);
							})}
						{/* File Input Field */}
						{/* <Stack sx={{ width: "35%" }} spacing={"3px"} height={"70px"}>
							<InputLabel sx={{ color: grey[600] }}>Agreement File</InputLabel>
							<TextField
								variant="outlined"
								size="small"
								type="file"
								value={agreementFile}
								onChange={handleFileChange}
								placeholder="Add Agreement file"
								required={true}
								fullWidth
							/>
						</Stack> */}
						<AddFiles
							columns={storeCapexTableColumns?.filter((data) => data.data_type === "FILE")}
							tableName={storesLinkedTables.store_capex_details.key}
							rowId={defaultData?.id}
							handleAddFile={handleAddFile}
							files={allFiles}
							selectedStore={selectedStore}
						/>
					</Box>
				</Stack>

				<DialogActions sx={{ pt: 2 }}>
					<Button variant="outlined" sx={{ justifySelf: "center" }} onClick={handleModalclose}>
						Cancel
					</Button>
					<Button variant="contained" sx={{ justifySelf: "center" }} type="submit">
						{type === addOrEditModelAction.UPDATE ? "UPDATE" : "CREATE"}
					</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
};

export default AddOrEditModal;
