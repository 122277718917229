import { DeleteRounded } from "@mui/icons-material";
import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { DataGrid } from "@mui/x-data-grid";
import { addOrEditModelAction, billerDetailsTableColumns, leaseDetails, storeRentTableColumns } from "components/EboStoreV4/data";
import { useState } from "react";
import AddOrEditModal from "./AddOrEditModal/AddOrEditModal";
import { converAttrToDatagridCol } from "data/globalData";
import { statusOptions } from "data/pimData";

const RentRevenueTable = ({ rentRevenueLists, createDetailsApi, selectedStore, setSelectedStore, handleUpdateDetails }) => {
	const [rendRevenueDetails, setRentRevenueDetails] = useState();
	const [addOrEditModal, setAddOrEditModal] = useState(false);
	const [addOrEditModalType, setAddOrEditModalType] = useState(addOrEditModelAction.CREATE);

	// useState(() => {
	// 	setDataArr(tableArr);
	// }, [tableArr]);

	const handleRowDelete = (row) => {
		// setDataArr(dataArr.filter((item) => item.id !== row.id));
	};
	const handleModalOpen = (type, row) => {
		setAddOrEditModalType(type);
		setRentRevenueDetails(row);
		setAddOrEditModal(true);
	};
	return (
		<Box display="flex" width="100%" flexWrap="wrap">
			<Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
				<Typography variant="button">Rent and Revenue share Details</Typography>
				<Box>
					<Button
						size="small"
						disabled={selectedStore.status === statusOptions.PUBLISHED.key}
						variant="contained"
						onClick={() => handleModalOpen(addOrEditModelAction.CREATE)}>
						Add
					</Button>
				</Box>
			</Box>
			<Divider sx={{ width: "100%" }} />
			<Box pt={1} width="100%">
				<DataGrid
					rows={rentRevenueLists || []}
					columns={[
						...storeRentTableColumns?.map((col) => converAttrToDatagridCol(col)),

						// {
						// 	headerName: "DELETE",
						// 	width: 150,
						// 	headerAlign: "center",
						// 	align: "center",

						// 	renderCell: (params) => {
						// 		return (
						// 			<Box display="flex" justifyContent="center" alignItems="center">
						// 				<IconButton variant="contained" color="error" onClick={() => handleRowDelete(params.row)}>
						// 					<DeleteRounded />
						// 				</IconButton>
						// 			</Box>
						// 		);
						// 	},
						// },
					]}
					sx={{
						minHeight: 220,
						maxHeight: 300,
						width: "100%",
						border: "none",
						"& .MuiDataGrid-row": {
							// borderBottom: "1px solid lightgray",
							cursor: "pointer",
							border: "none",
						},
						"& .MuiDataGrid-columnHeader": {
							cursor: "pointer",
							background: grey[300],
						},
						"& .MuiDataGrid-columnHeaders": {
							background: grey[300],
							// mb: 2,
							border: "none",
							borderRadius: 1,
							// padding: "10px 0px",
						},
						"& .MuiDataGrid-footer": {
							background: grey[300],
							// mb: 2,
							border: "none",
							borderRadius: 1,
							// padding: "10px 0px",
						},
					}}
					columnHeaderHeight={35}
					rowHeight={40}
					disableAutosize
					onRowClick={({ row }) => handleModalOpen(addOrEditModelAction.UPDATE, row)}
					// disableRowSelectionOnClick
					pageSizeOptions={[5, 10]}
				/>
			</Box>
			{addOrEditModal && (
				<AddOrEditModal
					modal={addOrEditModal}
					setModal={setAddOrEditModal}
					type={addOrEditModalType}
					selectedObj={rendRevenueDetails}
					createDetailsApi={createDetailsApi}
					selectedStore={selectedStore}
					setSelectedStore={setSelectedStore}
					handleUpdateDetails={handleUpdateDetails}
				/>
			)}
		</Box>
	);
};

export default RentRevenueTable;
