import { custom } from "./authData";

export const workforceApprovalRole = custom.WORKFORCEADMIN;
export const cashCardApprovalRole = custom.CASHCARDADMIN;

export const months = [
	{ name: "JAN", key: 1 },
	{ name: "FEB", key: 2 },
	{ name: "MAR", key: 3 },
	{ name: "APR", key: 4 },
	{ name: "MAY", key: 5 },
	{ name: "JUN", key: 6 },
	{ name: "JUL", key: 7 },
	{ name: "AUG", key: 8 },
	{ name: "SEP", key: 9 },
	{ name: "OCT", key: 10 },
	{ name: "NOV", key: 11 },
	{ name: "DEC", key: 12 },
];
export const monthStringArr = [
	{ name: "JAN", key: "JAN" },
	{ name: "FEB", key: "FEB" },
	{ name: "MAR", key: "MAR" },
	{ name: "APR", key: "APR" },
	{ name: "MAY", key: "MAY" },
	{ name: "JUN", key: "JUN" },
	{ name: "JUL", key: "JUL" },
	{ name: "AUG", key: "AUG" },
	{ name: "SEP", key: "SEP" },
	{ name: "OCT", key: "OCT" },
	{ name: "NOV", key: "NOV" },
	{ name: "DEC", key: "DEC" },
];
export const franchiseData = [
	{
		name: "ABACUS",
		key: "ABACUS INDIA AGENCIES PRIVATE LIMITED",
		stores: [
			"PACCIFIC",
			"AMBIENCE MALL",
			"MALL OF INDIA-NOIDA",
			"MERRUT",
			"ALIGARH",
			"LUCKNOW",
			"LULU LUCKNOW",
			"MODEL TOWN-JALANDHAR",
			"RSM SELECT CITY",
			"PACIFIC-TAGORE",
		],
	},
	{
		name: "SHIVERA",
		key: "SHIVERA LIFESTYLE PRIVATE LIMITED",
		stores: [
			"VAPI",
			"VAD",
			"CG AHM",
			"SURAT",
			"PRAHLADNAGAR_AHM",
			"RSM VR SURAT",
			"SINDHUBHAVAN",
			"LP SAVANI",
			"MOTERA",
			"RAJKOT",
			"NEXUS AHMEDABAD",
			"BOPAL",
			"GANDHIDHAM",
			"ANAND",
			"PALLADIUM AHMEDABAD",
			"GANDHI NAGAR",
		],
	},
	{
		name: "SAGE BOUNTY",
		key: "SAGE BOUNTY PRIVATE LIMITED",
		stores: ["THOR RAJHANS ORNATE-SURAT"],
	},
	{
		name: "MONCHER RETAIL",
		key: "MONCHER RETAIL LLP ",
		stores: ["SILIGURI", "CC SILIGURI"],
	},
	{
		name: "DREAM SHIVERA",
		key: "MONCHER RETAIL LLP ",
		stores: [
			"MI ROAD JAIPUR",
			"PMC-PUNE",
			"NEXUS SEAWOODS MUM",
			"RSM-PHOENIX PALLADIUM",
			"PAVILLION-PUNE",
			"NASHIK",
			"INORBIT VASHI",
			"VAISHALI NAGAR JAIPUR",
			"RSM PMC KURLA",
			"RSM PMC-PUNE",
			"RCITY MUM",
			"RSM OBEROI MALL",
			"URBAN SQUARE UDAIPUR",
			"C21 INDORE",
			"C21 INDORE",
			"GANGANAGAR",
			"PMC KURLA",
			"OBEROI-MALL",
			"C ROAD JODHPUR",
			"INFINITY MALAD",
			"PHOENIX - HIGH STREET",
			"AMRAVATI",
			"RSM INFINITY MALAD",
			"PROZONE AURANGABAD",
			"RSM-PHOENIX PALLADIUM-OLD",
			"MITTAL MALL AJMER",
		],
	},
	{
		name: "AJANTA ESPRESSO BAR",
		key: "AJANTA ESPRESSO BAR",
		stores: ["KAMLA NAGAR"],
	},
	{
		name: "SHREE JI CLOTH",
		key: "SHREE JI CLOTH",
		stores: ["RSM AMBUJA MALL RAIPUR"],
	},
	{
		name: "RENE FASHION",
		key: "RENE FASHION",
		stores: ["CITY CENTER PANDARI"],
	},
];

export const workforceFranchiseAssociation = "franchiseManpower";
export const cashCardFranchiseAssociation = "franchiseCashCard";
