import { ClearRounded } from "@mui/icons-material";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	Divider,
	IconButton,
	InputLabel,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import CustomTextField from "components/EboStoreV4/CommonComponents/CustomTextfield/CustomTextfield";
import RenderInputField from "components/EboStoreV4/CommonComponents/RenderInputFiled/RenderInputFiled";
import {
	addOrEditModelAction,
	autoUpdatestoreMainFieldsData,
	eboLiscenceTableColumns,
	getColDefaultValue,
	leaseDetails,
	liscenceTableColumns,
	storeBasicDetailsFields,
	storeMainFields,
	storesLinkedTables,
	storeTypeDetails,
} from "components/EboStoreV4/data";
import { constraintsOptions } from "data/globalData";
import { inputTypes, statusOptions } from "data/pimData";
import { agreementData } from "data/supplierPageData";
import { useEffect, useState } from "react";
import LiscenceTable from "../LiscenceTable";
import AddFiles from "components/EboStoreV4/CommonComponents/AddFiles/AddFiles";

const AddOrEditModal = ({
	modal,
	setModal,
	type,
	defaultData,
	createDetailsApi,
	selectedStore,
	setSelectedStore,
	handleUpdateDetails,
	handleFileUploadDetails,
	handleGetStoreFiles,
}) => {
	const defFields = (() => {
		let obj = {};
		eboLiscenceTableColumns?.forEach((attr) => {
			obj[attr.key] = getColDefaultValue(attr);
		});
		return obj;
	})();
	const [liscenceDetails, setLiscenceDetails] = useState(type === addOrEditModelAction.UPDATE ? defaultData : defFields);
	const [agreementFile, setAgreementFile] = useState();

	const [allFiles, setAllFiles] = useState([]);

	const handleCallback = (data) => {
		setAllFiles(data);
	};

	useEffect(() => {
		defaultData !== undefined &&
			handleGetStoreFiles(storesLinkedTables.store_liscence_details.key, defaultData?.id, handleCallback);
	}, []);
	const handleFileChange = (e) => {
		const file = e.target.files[0];
		setAgreementFile(file);
	};

	const handleAddFile = (data) => {
		setAllFiles([...allFiles, data]);
	};

	const handleModalclose = () => {
		setModal(false);
	};
	const handleLiscenceCallbackFun = (data) => {
		if (type === addOrEditModelAction.UPDATE) {
			let filtredData = selectedStore?.eboStoreLiscenceDetails.filter((data) => data.id !== liscenceDetails.id);
			let billerDetail = [...filtredData, liscenceDetails];
			setSelectedStore({ ...selectedStore, eboStoreLiscenceDetails: billerDetail });
		} else {
			let billerDetail = [...selectedStore?.eboStoreLiscenceDetails, data];
			setSelectedStore({ ...selectedStore, eboStoreLiscenceDetails: billerDetail });
		}
		setLiscenceDetails(defFields);
		setModal(false);
	};
	const handleFormSubmit = async (e) => {
		e.preventDefault();
		const filtredFiles = allFiles.filter((data) => data?.isNew);
		//this if for file upload
		let updatedData = await handleFileUploadDetails(
			filtredFiles,
			selectedStore.store_code,
			storesLinkedTables.store_liscence_details.key
		);
		let updatedBiller = { ...liscenceDetails, ebo_store_v4_id: selectedStore.store_code, files: updatedData };
		if (type === addOrEditModelAction.UPDATE) {
			await handleUpdateDetails(storesLinkedTables.store_liscence_details.seqMethod, updatedBiller, handleLiscenceCallbackFun);
		} else {
			createDetailsApi(storesLinkedTables.store_liscence_details.seqMethod, updatedBiller, handleLiscenceCallbackFun);
		}
	};

	return (
		<Dialog maxWidth="md" fullWidth open={modal} onClose={handleModalclose} component="form" onSubmit={handleFormSubmit}>
			<Box p={2}>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Typography variant="h6">{type === addOrEditModelAction.UPDATE ? "Update" : "Add"} Liscence Data</Typography>
					<Box>
						<IconButton onClick={handleModalclose}>
							<ClearRounded />
						</IconButton>
					</Box>
				</Box>
				<Stack spacing={1} width="100%">
					{" "}
					<Box display="flex" columnGap={"10px"} rowGap="10px" flexWrap="wrap" width="100%">
						{eboLiscenceTableColumns?.map((row) => {
							return (
								<RenderInputField
									column={row}
									data={liscenceDetails}
									setData={setLiscenceDetails}
									width="24%"
									selectOptObjName={"option_data"}
								/>
							);
						})}
						{/* File Input Field */}
						{/* <Stack sx={{ width: "35%" }} spacing={"3px"} height={"70px"}>
							<InputLabel sx={{ color: grey[600] }}>Agreement File</InputLabel>
							<TextField
								variant="outlined"
								size="small"
								type="file"
								value={agreementFile}
								onChange={handleFileChange}
								placeholder="Add Agreement file"
								required={true}
								fullWidth
							/>
						</Stack> */}
						<AddFiles
							columns={liscenceTableColumns}
							tableName={storesLinkedTables.store_liscence_details.key}
							rowId={defaultData?.id}
							handleAddFile={handleAddFile}
							files={allFiles}
							selectedStore={selectedStore}
						/>
					</Box>
				</Stack>

				<DialogActions sx={{ pt: 2 }}>
					<Button variant="outlined" sx={{ justifySelf: "center" }} onClick={handleModalclose}>
						Cancel
					</Button>
					<Button
						variant="contained"
						disabled={selectedStore.status === statusOptions.PUBLISHED.key}
						sx={{ justifySelf: "center" }}
						type="submit">
						{type === addOrEditModelAction.UPDATE ? "UPDATE" : "CREATE"}
					</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
};

export default AddOrEditModal;
