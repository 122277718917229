import { ClearRounded, FlashlightOffOutlined } from "@mui/icons-material";
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogTitle,
	Divider,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { getColDefaultValue } from "components/EboStoreV4/data";
import { useState } from "react";
import RenderInputField from "../RenderInputFiled/RenderInputFiled";
import { arrType, converAttrToDatagridCol, datatypes, inputTypes } from "data/globalData";
import { DataGrid } from "@mui/x-data-grid";
import { grey } from "@mui/material/colors";
import { GlobalState } from "context/GlobalContext";
import { statusOptions } from "data/pimData";

export const addFilesCol = [
	{
		name: "Effective Date",
		key: "effective_date",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: false, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.DATE.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "File Name",
		key: "file_name",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: false, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "File Type",
		key: "file_type",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: false, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	// {
	// 	name: "Upload File",
	// 	key: "file",
	// 	input_type: inputTypes.ENTER.key,
	// 	constraints: { NOTNULL: true, DISPLAY: "false", UNIQUE: false },
	// 	data: {},
	// 	data_type: datatypes.FILE.key,
	// 	sort_no: 1,
	// 	group: "",
	// },
	{
		name: "URL",
		key: "url",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: false, DISPLAY: "false", UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
];

const AddFiles = ({ tableName, rowId, columns, handleAddFile, type, files, selectedStore }) => {
	const defFields = (() => {
		let obj = {};
		addFilesCol?.forEach((attr) => {
			obj[attr.key] = getColDefaultValue(attr);
		});
		return obj;
	})();
	const [fileDetails, setFileDetails] = useState(defFields);
	const [file, setFile] = useState(null);
	const [newFileData, setNewFileData] = useState({});
	const [loading, setLoading] = useState(false);
	const { setSnackBar, snackBar, setSnackBarOpen, loginUser, setLoadingOpen } = GlobalState();
	const filtTypeOptions = {
		type: arrType,
		list: columns,
	};
	const handleFileChange = (e) => {
		const newFile = e.target.files[0];
		const newFileName = `${newFile.name}_${new Date().valueOf()}`;
		const modifiedFile = new File([newFile], newFileName, { type: newFile.type });
		const formData = new FormData();
		formData.append("files", modifiedFile);
		console.log("formData", formData);
		setFile(modifiedFile);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (fileDetails.file_name === "" || fileDetails.file_type === "" || file === null || fileDetails.effective_date === null) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: `PLease add required File details`,
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			return;
		}
		const data = {
			id: new Date().valueOf(),
			table_name: tableName,
			table_row_id: rowId?.toString(),
			file_name: fileDetails.file_name,
			file_type: fileDetails.file_type,
			effective_date: fileDetails.effective_date,
			file: file,
			url: "To be uploaded",
			isNew: true,
		};
		handleAddFile(data);
		setFileDetails(defFields);
		setFile(null);
		document.getElementById("file").value = "";
	};

	const formStack = {
		width: { xs: "80%", sm: "40%", md: "25%" },
	};
	return (
		<Stack
			direction="row"
			spacing={2}
			width="100%"
			alignItems="center"
			flexWrap="wrap"
			rowGap={1}
			// sx={{ backgroundColor: grey[100], borderRadius: 2 }}
		>
			<Box pt={1} width="100%">
				{files.length > 0 && (
					<Typography variant="h6" pt={2} width="100%">
						Files
					</Typography>
				)}
				{files.length > 0 && (
					<DataGrid
						rows={files || []}
						getRowId={(row) => row.id}
						columns={[...addFilesCol?.map((col) => converAttrToDatagridCol(col))]}
						sx={{
							minHeight: 220,
							maxHeight: 300,
							width: "100%",
							border: "none",
							"& .MuiDataGrid-row": {
								// borderBottom: "1px solid lightgray",
								cursor: "pointer",
								border: "none",
							},
							"& .MuiDataGrid-columnHeader": {
								cursor: "pointer",
								background: grey[300],
							},
						}}
						columnHeaderHeight={35}
						rowHeight={40}
						disableAutosize
						// onRowClick={({ row }) => handleModalOpen(addOrEditModelAction.UPDATE, row)}
						disableRowSelectionOnClick
						pageSizeOptions={[5, 10]}
					/>
				)}
			</Box>
			<Typography variant="h6" pt={2} width="100%">
				Add Files
			</Typography>
			<Box display="flex" columnGap={"10px"} rowGap="10px" flexWrap="wrap" width="100%">
				{addFilesCol?.map((row) => {
					if (row.key === "url") return null;
					let options = {};
					if (row.key === "file_type") {
						options = filtTypeOptions;
					}
					return (
						<RenderInputField
							column={{ ...row, options }}
							data={fileDetails}
							setData={setFileDetails}
							width="24%"
							selectOptObjName={"options"}
						/>
					);
				})}
				<Stack sx={formStack} spacing={"3px"}>
					<InputLabel>Upload File</InputLabel>
					<TextField
						size="small"
						type="file"
						id="file"
						onChange={handleFileChange}
						placeholder="Enter director/owner name"
						// required
					/>
				</Stack>
			</Box>
			<Box display="flex" justifyContent="center">
				<Button
					disabled={selectedStore.status === statusOptions.PUBLISHED.key}
					startIcon={loading && <CircularProgress color="inherit" size="20px" />}
					variant="contained"
					sx={{ margin: "auto", width: 180, m: 2 }}
					onClick={handleSubmit}>
					Add File
				</Button>
			</Box>
		</Stack>
	);
};
export default AddFiles;
