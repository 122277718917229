import moment from "moment";

const { Box, Stack, InputLabel, TextField, Button, Select, MenuItem } = require("@mui/material");

const StartDateEndDateCopm = ({
	setStartDate,
	startDate,
	endDate,
	setEndDate,
	handleGetResult,
	disabled,
	setDropdownValue,
	dropdownValue,
	dropdownList,
	min,
}) => {
	return (
		<>
			<Box display="flex" columnGap={2} paddingBottom={2}>
				<Stack spacing={1}>
					<InputLabel>Select Start Date</InputLabel>
					<TextField
						size="small"
						type="date"
						value={startDate}
						onChange={(e) => setStartDate(e.target.value)}
						required
						placeholder="compliance certificate issue date"
						inputProps={{
							min: min ? min : "2023-05-01",
							max: moment(new Date()).format("YYYY-MM-DD"),
						}}
					/>
				</Stack>
				<Stack spacing={1}>
					<InputLabel>Select End Date</InputLabel>
					<TextField
						size="small"
						type="date"
						value={endDate}
						onChange={(e) => setEndDate(e.target.value)}
						required
						placeholder="compliance certificate issue date"
						inputProps={{
							min: min ? min : "2023-05-01",
							max: moment(new Date()).format("YYYY-MM-DD"),
						}}
					/>
				</Stack>
				{dropdownList && dropdownList.length > 0 && (
					<Stack spacing={1}>
						<InputLabel>Type</InputLabel>
						<Select
							size="small"
							width="100px"
							value={dropdownValue}
							MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
							onChange={(e) => setDropdownValue(e.target.value)}>
							{dropdownList.map((list) => (
								<MenuItem value={list}>{list}</MenuItem>
							))}
						</Select>
					</Stack>
				)}
				<Stack sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
					<Button
						variant="contained"
						sx={{ width: "150px", height: "40px" }}
						type="submit"
						disabled={disabled}
						// startIcon={loading ? <CircularProgress color="inherit" size="20px" /> : <CodeIcon />}
						onClick={() => handleGetResult()}>
						Get Result
					</Button>
				</Stack>
			</Box>
		</>
	);
};

export default StartDateEndDateCopm;
